import React from "react";
import styles from "./PublicationSection.module.scss";
import ImageTitleAndDescription from "@components/ImageTitleAndDescription/ImageTitleAndDescription";
import publications from "@data/publications";
import cn from "classnames";
import useGallery from "@hooks/useGallery";
import portadaZapiolaWildprint from "@assets/images/projects/publications/zapiolaWildprint/portadaZapiolaWildprint.jpg";
import portadaLocalesClarin from "@assets/images/projects/publications/localesClarin/portadaLocalesClarin.jpg";
import portadaVlLiving from "@assets/images/projects/publications/vlLiving/portadaVlLiving.jpg";
import portadaCervantesClarin from "@assets/images/projects/publications/cervantesClarin/portadaCervantesClarin.jpg";
import { useTranslation } from "react-i18next";
//mobile
import ProjectCardMobile from "@components/ProjectCardMobile/ProjectCardMobile";

function PublicationSection() {
  const { ShowGallery, handleOpenGallery } = useGallery();
  const [t, i18n] = useTranslation("global");

  const isMobile = window.innerWidth <= 1000;

  return (
    <div id="publicationsSection" className={styles.publicationContainer}>
      <div className={styles.publicationTitleContainer}>
        <div className={styles.publicationFirstTitle}>
          {t("publications.publicationsTitle")}
        </div>
      </div>
      {isMobile ? (
        <>
          {publications.map((item) => (
            <div
              className={styles.publicationsContainerMobile}
              key={item.id}
              onClick={() => handleOpenGallery(item.images)}
            >
              <ProjectCardMobile
                key={item.id}
                customTitle={item.name}
                title={
                  i18n.language === "es" || item.nameEn === null
                    ? item.nameEs
                    : item.nameEn
                }
                number={(item.id + 1).toString().padStart(2, "0")}
                portada={item.mobilePortada}
                swap={false}
              />
            </div>
          ))}
        </>
      ) : (
        <div>
          <div className={styles.publicationProjectsFirstRow}>
            <div className={styles.projectCard}>
              <img
                loading="lazy"
                src={portadaZapiolaWildprint}
                alt=""
                width={394}
                height={394}
                onClick={() => handleOpenGallery(publications[0].images)}
              />
              <ImageTitleAndDescription
                customTitle="ZAPIOLA"
                title={
                  i18n.language === "es" || publications[0].nameEn === null
                    ? publications[0].nameEs
                    : publications[0].nameEn
                }
                number="01"
                positionY="top"
                positionYValue={160}
                positionX="left"
                positionXValue={380}
                swap={false}
              />
            </div>
            <div
              className={cn(
                styles.projectCard,
                styles.projectCardExtraMarginTop
              )}
            >
              <img
                loading="lazy"
                src={portadaLocalesClarin}
                alt=""
                width={473}
                height={269}
                onClick={() => handleOpenGallery(publications[1].images)}
              />
              <ImageTitleAndDescription
                customTitle="LOCALES"
                title={
                  i18n.language === "es" || publications[1].nameEn === null
                    ? publications[1].nameEs
                    : publications[1].nameEn
                }
                number="02"
                positionY="bottom"
                positionYValue={-40}
                positionX="left"
                positionXValue={-80}
                swap={false}
              />
            </div>
          </div>
          <div className={styles.publicationProjectsSecondRow}>
            <div
              className={cn(
                styles.projectCard,
                styles.projectCardExtraMarginTop
              )}
            >
              <img
                loading="lazy"
                src={portadaVlLiving}
                alt=""
                width={276}
                height={391}
                onClick={() => handleOpenGallery(publications[2].images)}
              />
              <ImageTitleAndDescription
                customTitle="BL"
                title={
                  i18n.language === "es" || publications[2].nameEn === null
                    ? publications[2].nameEs
                    : publications[2].nameEn
                }
                number="03"
                positionY="top"
                positionYValue={200}
                positionX="left"
                positionXValue={270}
                swap={false}
              />
            </div>
            <div
              className={cn(
                styles.projectCard,
                styles.projectCardExtraMarginTop
              )}
            >
              <img
                loading="lazy"
                src={portadaCervantesClarin}
                alt=""
                width={548}
                height={338}
                onClick={() => handleOpenGallery(publications[3].images)}
              />
              <ImageTitleAndDescription
                customTitle="CERVANTES"
                title={
                  i18n.language === "es" || publications[3].nameEn === null
                    ? publications[3].nameEs
                    : publications[3].nameEn
                }
                number="04"
                positionY="bottom"
                positionYValue={10}
                positionX="left"
                positionXValue={-100}
                swap={false}
              />
            </div>
          </div>
        </div>
      )}

      <ShowGallery />
    </div>
  );
}

export default PublicationSection;
