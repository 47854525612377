import React from "react";
import Header from "@components/Header/Header";
import Footer from "@components/Footer/Footer";
import ResidentialSection from "@components/ResidentialSection/ResidentialSection";

function ResidentialPage() {
  return (
    <div>
      <Header />
      <ResidentialSection />
      <Footer />
    </div>
  );
}

export default ResidentialPage;
