import React from "react";
import Header from "@components/Header/Header";
import Footer from "@components/Footer/Footer";
import PublicationSection from "@components/PublicationSection/Publication";

function PublicationsPage() {
  return (
    <div>
      <Header />
      <PublicationSection />
      <Footer />
    </div>
  );
}

export default PublicationsPage;
