import { Routes, Route } from "react-router-dom";
import "./App.scss";
import Landing from "@pages/Landing/Landing";
import ResidentialPage from "@pages/ResidentialPage/ResidentialPage";
import ExperimentationPage from "@pages/ExperimentationPage/ExperimentationPage";
import PublicationsPage from "@pages/PublicationsPage/PublicationsPage";
import ComercialPage from "@pages/ComercialPage/ComercialPage";
import TeamPage from "@pages/TeamPage/TeamPage";
import { Routes as RoutesNames } from "@constants/routes";
import DetailPage from "@pages/DetailPage/DetailPage";
// TODO: import logo from "./logo.svg";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route
          path={`/${RoutesNames.Residential}`}
          element={<ResidentialPage />}
        />
        <Route path={`/${RoutesNames.Comercial}`} element={<ComercialPage />} />
        <Route
          path={`/${RoutesNames.Experimentations}`}
          element={<ExperimentationPage />}
        />
        <Route
          path={`/${RoutesNames.Publications}`}
          element={<PublicationsPage />}
        />
        <Route path={`/${RoutesNames.Team}`} element={<TeamPage />} />
        <Route path={`/:projectType/:projectName`} element={<DetailPage />} />
      </Routes>
    </div>
  );
}

export default App;
