import React from "react";
import styles from "./ImageTitleAndDescription.module.scss";
import { useTranslation } from "react-i18next";

function ImageTitleAndDescription({
  title,
  number,
  positionY,
  positionYValue,
  positionX,
  positionXValue,
  customTitle,
  swap = true,
}) {
  const { t, i18n } = useTranslation("global");
  const componentStyle = {
    [positionY]: `${positionYValue}px`,
    [positionX]: `${positionXValue}px`,
  };

  const displayTitle = customTitle || t("residential.house");

  return (
    <div
      className={styles.imageTitleAndDescriptionContainer}
      style={componentStyle}
    >
      <div className={styles.imageTitleAndDescription}>
        <div className={styles.imageTitleContainer}>
          {i18n.language === "es" || swap === false ? (
            <>
              <div
                className={styles.imageTitleFirst}
                style={{ display: displayTitle === "null" ? "none" : "block" }}
              >
                {displayTitle}
              </div>
              <div className={styles.imageTitleSecond}>{title}</div>
            </>
          ) : (
            <>
              <div className={styles.imageTitleSecond}>{title}</div>
              &nbsp;
              <div
                className={styles.imageTitleFirst}
                style={{ display: displayTitle === "null" ? "none" : "block" }}
              >
                {displayTitle}
              </div>
            </>
          )}
        </div>
        <div className={styles.imageDescription}>{t("copyright")}</div>
      </div>
      <div className={styles.imageNumber}>{number}</div>
    </div>
  );
}

export default ImageTitleAndDescription;
