import { useState } from "react";
import Gallery from "@components/Gallery/Gallery";

const useGallery = () => {
  const [autoplay, setAutoPlay] = useState(true);
  const [showGallery, setShowGallery] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const closeGallery = () => {
    setAutoPlay(true);
    setSelectedImages([]);
    setShowGallery(false);
  };

  const handleOpenGallery = (images) => {
    setAutoPlay(false);
    setSelectedImages(images);
    setShowGallery(true);
  };

  const ShowGallery = () => {
    if (!showGallery) {
      return null;
    }
    return <Gallery images={selectedImages} onClose={closeGallery} />;
  };

  return {
    showGallery,
    selectedImages,
    autoplay,
    ShowGallery,
    handleOpenGallery,
    setShowGallery,
    setSelectedImages,
    closeGallery,
  };
};

export default useGallery;
