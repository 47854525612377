import React from "react";
import {
  INSTAGRAM_URL,
  BEHANCE_URL,
  EMAIL_URL,
  WHATS_APP_URL,
} from "@constants/urls";
import styles from "./Footer.module.scss";
import behanceIcon from "@assets/images/icons/be-icon.png";
import emailIcon from "@assets/images/icons/email-icon.png";
import instagramIcon from "@assets/images/icons/instagram-icon.png";
import whatsappIcon from "@assets/images/icons/whatsapp-icon.png";
import { Routes as RoutesNames } from "@constants/routes";

function Footer({ currentPage }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer id="footer" className={styles.footerSticky}>
      <div>
        <p>GrizzoStudio Buenos Aires, Argentina</p>
      </div>
      <div className={styles.footerRightSide}>
        <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
          <img
            src={instagramIcon}
            alt="Instagram"
            className={styles.footerImage}
          />
        </a>
        <a href={BEHANCE_URL} target="_blank" rel="noopener noreferrer">
          <img src={behanceIcon} alt="Behance" className={styles.footerImage} />
        </a>
        <a
          href={`mailto:${EMAIL_URL}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={emailIcon} alt="Email" className={styles.footerImage} />
        </a>
        <a href={WHATS_APP_URL} target="_blank" rel="noopener noreferrer">
          <img
            src={whatsappIcon}
            alt="WhatsApp"
            className={styles.footerImage}
          />
        </a>
        {currentPage === RoutesNames.Landing ? (
          <p className={styles.scrollTop} onClick={scrollToTop}>
            Top
          </p>
        ) : (
          <a href={"/"} className={styles.scrollTop}>
            Back
          </a>
        )}
      </div>
    </footer>
  );
}

export default Footer;
