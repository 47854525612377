import React from "react";

import styles from "./ProjectCardMobile.module.scss";
import ImageTitleAndDescription from "@components/ImageTitleAndDescription/ImageTitleAndDescription";

function ProjectCardMobile({
  customTitle = null,
  title,
  number,
  portada,
  swap = false,
}) {
  return (
    <div>
      <div>
        <img
          loading="lazy"
          src={portada}
          alt=""
          className={styles.projectCardMobileImg}
          // onClick={handleOpenGallery(publications[0].images)}
        />
        <ImageTitleAndDescription
          customTitle={customTitle}
          title={title}
          number={number}
          swap={swap}
          // positionY="top"
          // positionYValue={160}
          // positionX="left"
          // positionXValue={380}
        />
      </div>
    </div>
  );
}

export default ProjectCardMobile;
