import React, { useRef } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import styles from "./Gallery.module.scss";
import useCloseOnEsc from "@hooks/useCloseOnEsc";
import closeIcon from '@assets/images/icons/closeIcon.png'
import chevronLeft from '@assets/images/icons/chevronLeft.png'
import chevronRight from '@assets/images/icons/chevronRight.png'

function Gallery({ images, onClose }) {
  const galleryRef = useRef(null);

  const projectImages = images.map((imagePath) => ({
    original: imagePath,
  }));

  useCloseOnEsc(onClose);

  const goToNext = () => {
    const currentIndex = galleryRef.current.getCurrentIndex()
    galleryRef.current.slideToIndex(currentIndex+1)
  }

  const goToPrev = () => {
    const currentIndex = galleryRef.current.getCurrentIndex()
    galleryRef.current.slideToIndex(currentIndex-1)
  }

  return (
    <div className={styles.galleryOverlay}>
      <div className={styles.closeIconContainer} onClick={onClose}>
        <img
          src={closeIcon}
          alt="closeIcon"
        />
      </div>
      <div className={styles.imageGalleryContainer}>
        <button
          type="button"
          onClick={goToPrev}
          aria-label="Previous Slide"
          className={styles.leftNav}
        >
          <img src={chevronLeft} alt="chevronLeft"/>
        </button>
        <ImageGallery
          ref={galleryRef}
          items={projectImages}
          onClose={onClose} 
          showPlayButton={false}
          lazyLoad
          loading='lazy'
          showFullscreenButton={false}
          showNav={false}
        />
          <button
          type="button"
          onClick={goToNext}
          aria-label="Next Slide"
          className={styles.rightNav}
        >
          <img src={chevronRight} alt="chevronRight"/>
        </button>
      </div>
    </div>
  );
}

export default Gallery;
