import React from "react";
import styles from "./CommercialSection.module.scss";
import ImageTitleAndDescription from "@components/ImageTitleAndDescription/ImageTitleAndDescription";
import commercialProjects from "@data/commercialProjects";
import cn from "classnames";
import portadaEstudio from "@assets/images/projects/commercial/elEstudio/portadaElEstudio.jpg";
import portadaZapiola from "@assets/images/projects/commercial/zapiola/portadaZapiola.jpg";
import portadaLaExposicion from "@assets/images/projects/commercial/laExposicion/portadaLaExposicion.jpg";
import portadaMarcia from "@assets/images/projects/commercial/marcia/portadaMarcia.jpg";
import portadaCacto from "@assets/images/projects/commercial/cacto/portadaCacto.jpg";
import portadaNorte from "@assets/images/projects/commercial/norte/portadaNorte.jpg";
import portadaSorvo from "@assets/images/projects/commercial/sorvo/portadaSorvo.jpg";
import portadaMontagu from "@assets/images/projects/commercial/montagu/portadaMontagu.jpg";
import portadaArena from "@assets/images/projects/commercial/arena/portadaArena.jpg";
import portadaSimbron from "@assets/images/projects/commercial/simbron/portadaSimbron.jpg";
import { useNavigate } from "react-router-dom";
import { Routes as RoutesNames } from "@constants/routes";
import { useTranslation } from "react-i18next";
import useGallery from "@hooks/useGallery";
import ProjectCardMobile from "@components/ProjectCardMobile/ProjectCardMobile";

function CommercialSection() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  const handleOpenCarousel = (project) => () => {
    const projectNameFormatted = project.name.toLowerCase().replace(/\s+/g, "");

    navigate(`/${RoutesNames.Comercial}/${projectNameFormatted}`, {
      state: { project },
    });
  };

  const { ShowGallery, handleOpenGallery } = useGallery();

  const isMobile = window.innerWidth <= 1000;

  return (
    <div className={styles.commercialContainer}>
      <div className={styles.commercialTitleContainer}>
        <div className={styles.commercialFirstTitle}>
          {t("commercial.firstTitle")}
        </div>
        <div className={styles.commercialSecondTitle}>
          {t("commercial.secondTitle")}
        </div>
      </div>
      {isMobile ? (
        <>
          {commercialProjects.map((item) => (
            <div
              className={styles.comercialContainerMobile}
              key={item.id}
              onClick={() => handleOpenGallery(item.galleryImages)}
            >
              <ProjectCardMobile
                key={item.id}
                customTitle={"null"}
                title={
                  i18n.language === "es" ||
                  item.nameEn === null ||
                  item.nameEn === undefined
                    ? item.name
                    : item.nameEn
                }
                number={(item.id + 1).toString().padStart(2, "0")}
                portada={item.mobilePortada}
                swap={true}
              />
            </div>
          ))}
        </>
      ) : (
        <>
          <div className={styles.commercialProjectsFirstRow}>
            <div
              className={cn(styles.projectCard, styles.projectCardMarginTop)}
            >
              <img
                loading="lazy"
                src={portadaLaExposicion}
                alt=""
                width={395}
                height={395}
                onClick={handleOpenCarousel(commercialProjects[2])}
              />
              <ImageTitleAndDescription
                customTitle={t("commercial.expoFirstWord")}
                title={t("commercial.expoSecondWord")}
                number="03"
                positionY="top"
                positionYValue={415}
              />
            </div>
            <div
              className={cn(styles.projectCard, styles.projectCardMarginTop)}
            >
              <img
                loading="lazy"
                src={portadaZapiola}
                alt=""
                width={264}
                height={391}
                onClick={handleOpenCarousel(commercialProjects[1])}
              />
              <ImageTitleAndDescription
                customTitle="null"
                title="ZAPIOLA"
                number="02"
                positionY="bottom"
                positionYValue={10}
                positionX="left"
                positionXValue={280}
              />
            </div>
            <div className={styles.projectCard}>
              <img
                loading="lazy"
                src={portadaEstudio}
                alt=""
                width={373}
                height={269}
                onClick={handleOpenCarousel(commercialProjects[0])}
              />
              <ImageTitleAndDescription
                customTitle={t("commercial.studyFirstWord")}
                title={t("commercial.studySecondWord")}
                number="01"
                positionY="top"
                positionYValue={-44}
                positionX="left"
                positionXValue={-165}
              />
            </div>
          </div>
          <div className={styles.commercialProjectsSecondRow}>
            <div
              className={cn(styles.projectCard, styles.projectCardMarginTop)}
            >
              <img
                loading="lazy"
                src={portadaMarcia}
                alt=""
                width={791}
                height={397}
                onClick={handleOpenCarousel(commercialProjects[3])}
              />
              <ImageTitleAndDescription
                customTitle="null"
                title="MARCIA"
                number="04"
                positionY="top"
                positionYValue={220}
                positionX="left"
                positionXValue={790}
              />
            </div>
          </div>
          <div className={styles.commercialProjectsThirdRow}>
            <div className={styles.commercialProjectsThirdRowLeftColumn}>
              <div className={styles.projectCard}>
                <img
                  loading="lazy"
                  src={portadaNorte}
                  alt=""
                  width={295}
                  height={380}
                  onClick={handleOpenCarousel(commercialProjects[5])}
                />
                <ImageTitleAndDescription
                  customTitle="null"
                  title="NORTE"
                  number="06"
                  positionY="top"
                  positionYValue={190}
                  positionX="left"
                  positionXValue={290}
                />
              </div>
              <div
                className={cn(styles.projectCard, styles.projectCardMarginTop)}
              >
                <img
                  loading="lazy"
                  src={portadaSorvo}
                  alt=""
                  width={778}
                  height={293}
                  onClick={handleOpenCarousel(commercialProjects[6])}
                />
                <ImageTitleAndDescription
                  customTitle="null"
                  title="SORVO"
                  number="07"
                  positionY="bottom"
                  positionYValue={-40}
                  positionX="left"
                  positionXValue={360}
                />
              </div>
              <div
                className={cn(styles.projectCard, styles.projectCardMarginTop)}
              >
                <img
                  loading="lazy"
                  src={portadaArena}
                  alt=""
                  width={276}
                  height={393}
                  onClick={handleOpenCarousel(commercialProjects[8])}
                />
                <ImageTitleAndDescription
                  customTitle="null"
                  title="ARENA"
                  number="09"
                  positionY="bottom"
                  positionYValue={170}
                  positionX="left"
                  positionXValue={260}
                />
              </div>
            </div>
            <div className={styles.commercialProjectsThirdRowRightColumn}>
              <div className={styles.projectCard}>
                <img
                  loading="lazy"
                  src={portadaCacto}
                  alt=""
                  width={365}
                  height={287}
                  onClick={() =>
                    handleOpenGallery(commercialProjects[4].galleryImages)
                  }
                />
                <ImageTitleAndDescription
                  customTitle="null"
                  title="CACTO"
                  number="05"
                  positionY="top"
                  positionYValue={-30}
                  positionX="left"
                  positionXValue={-400}
                />
              </div>
              <div
                className={cn(styles.projectCard, styles.projectCardMarginTop)}
              >
                <img
                  loading="lazy"
                  src={portadaMontagu}
                  alt=""
                  width={380}
                  height={426}
                  onClick={handleOpenCarousel(commercialProjects[7])}
                />
                <ImageTitleAndDescription
                  customTitle="null"
                  title="MONTAGU"
                  number="08"
                  positionY="bottom"
                  positionYValue={-40}
                  positionX="left"
                  positionXValue={-30}
                />
              </div>
              <div
                className={cn(styles.projectCard, styles.projectCardMarginTop)}
              >
                <img
                  loading="lazy"
                  src={portadaSimbron}
                  alt=""
                  width={548}
                  height={337}
                  onClick={handleOpenCarousel(commercialProjects[9])}
                />
                <ImageTitleAndDescription
                  customTitle="null"
                  title="SIMBRON"
                  number="10"
                  positionY="bottom"
                  positionYValue={-40}
                  positionX="left"
                  positionXValue={-120}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <ShowGallery />
    </div>
  );
}

export default CommercialSection;
