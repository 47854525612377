import React from "react";
import styles from "./AboutSection.module.scss";
import aboutBackground from "@assets/images/backgrounds/background_about.jpg";
import { Link } from "react-router-dom";
import { Routes as RoutesNames } from "@constants/routes";
import { scrollToTop } from "@utils/scrollToTop";
import { useTranslation } from "react-i18next";

function AboutSection() {
  const [t] = useTranslation("global");

  return (
    <div className={styles.aboutSection}>
      <h1 className={styles.aboutSectionTitle}>
        <span className={styles.grizzoText}>GRIZZO</span>
        <span className={styles.aboutText}>ABOUT</span>
      </h1>
      <div className={styles.aboutSectionImageContainer}>
        <div className={styles.buttonPublications}>
          <Link to={RoutesNames.Publications} className={styles.button}>
            <span onClick={scrollToTop}>{t("landing.publications")}</span>
          </Link>
        </div>
        <div className={styles.buttonTeam}>
          <Link to={RoutesNames.Team} className={styles.button}>
            <span onClick={scrollToTop}>{t("landing.aboutUs")}</span>
          </Link>
        </div>
        <img
          src={aboutBackground}
          alt="Grizzo about"
          className={styles.aboutSectionImage}
        />
      </div>
    </div>
  );
}

export default AboutSection;
