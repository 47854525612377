import React, { useEffect, useState } from "react";
import cn from "classnames";
import useScrolling from "@hooks/useScrolling";
import styles from "./Header.module.scss";
import headerLogo from "@assets/images/backgrounds/headerLogo.png";
import headerLogoMobile from "@assets/images/backgrounds/mobile/headerLogoMobileRecortada.png";
import { scrollToSection } from "@utils/scrollToSection";
import { Link } from "react-router-dom";
import { Routes as RoutesNames } from "@constants/routes";
import { scrollToTop } from "@utils/scrollToTop";
import { useTranslation } from "react-i18next";

function Header({ currentPage }) {
  const { t, i18n } = useTranslation("global");
  const { scrolling } = useScrolling(0);
  const { scrolling: scrollingMenu } = useScrolling(370);
  const [menuVisible, setMenuVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLanguageChange = () => {
    const newLanguage = i18n.language === "es" ? "en" : "es";
    i18n.changeLanguage(newLanguage);
  };
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);

  return (
    <div id="header" className={styles.headerContainer}>
      <div
        className={cn(styles.headerSticky, {
          [styles.headerStickyScrolling]: scrolling,
          [styles.headerStickySection]: currentPage !== RoutesNames.Landing,
        })}
      >
        <div className={styles.headerFirstRow}>
          <div className={styles.languageSwitch} onClick={handleLanguageChange}>
            {i18n.language === "es" ? (
              <span>
                <strong>{t("Español")}</strong>/English
              </span>
            ) : (
              <span>
                Spanish/<strong>{t("English")}</strong>
              </span>
            )}
          </div>
          <div className={styles.hamburgerMenu} onClick={toggleMenu}>
            ☰
          </div>
        </div>
        <div
          className={cn(styles.hamburgerMenuContainer, {
            [styles.menuVisible]: menuVisible,
            [styles.hamburgerMenuContainerScrolling]: scrollingMenu,
          })}
        >
          <div className="">
            {currentPage === RoutesNames.Landing ? (
              <>
                <div
                  className={cn(styles.menuLink, {
                    [styles.menuLinkScrolling]: scrollingMenu,
                  })}
                >
                  <Link
                    onClick={scrollToTop}
                    to={`/${RoutesNames.Experimentations}`}
                  >
                    {t("menu.workshop")}
                  </Link>
                </div>
                <div
                  className={cn(styles.menuLink, {
                    [styles.menuLinkScrolling]: scrollingMenu,
                  })}
                  onClick={scrollToSection("projectsSection")}
                >
                  {t("menu.projects")}
                </div>
                <div
                  className={cn(styles.menuLink, {
                    [styles.menuLinkScrolling]: scrollingMenu,
                  })}
                >
                  <Link onClick={scrollToTop} to={`/${RoutesNames.Team}`}>
                    {t("menu.aboutUs")}
                  </Link>
                </div>
                <div
                  className={cn(styles.menuLink, {
                    [styles.menuLinkScrolling]: scrollingMenu,
                  })}
                >
                  <Link
                    onClick={scrollToTop}
                    to={`/${RoutesNames.Publications}`}
                  >
                    {t("menu.publications")}
                  </Link>
                </div>
              </>
            ) : (
              <div className={cn(styles.menuLink, styles.menuLinkScrolling)}>
                <Link onClick={scrollToTop} to="/">
                  {t("menu.home")}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      {currentPage === RoutesNames.Landing ? (
        <div>
          <div className={styles.headerBackground}>
            <div className={styles.headerBackgroundLogo}>
              <img
                src={windowWidth <= 768 ? headerLogoMobile : headerLogo}
                alt="Logo"
              />
            </div>
            <div className={styles.headerDescription}>
              <div className={styles.headerDescriptionLeft}>
                {t("header.leftSubtitle")}
              </div>
              <div className={styles.headerDescriptionRight}>
                {t("header.rightSubtitle")}
                <br />
                {t("header.location")}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Header;
