import React from "react";
import styles from "./ResidentialSection.module.scss";
import ImageTitleAndDescription from "@components/ImageTitleAndDescription/ImageTitleAndDescription";
import residentialProjects from "@data/residentialProjects";
import cn from "classnames";
import portadaCasaCacho from "@assets/images/projects/residential/casaCacho/portadaCasaCacho.jpg";
import portadaCasaBosque from "@assets/images/projects/residential/casaBosque/portadaCasaBosque.jpg";
import portadaCasaCervantes from "@assets/images/projects/residential/casaCervantes/portadaCasaCervantes.jpg";
import portadaCasaGutemberg from "@assets/images/projects/residential/casaGutemberg/portadaCasaGutemberg.jpg";
import portadaCasaLomadas from "@assets/images/projects/residential/casaLomadas/portadaCasaLomadas.jpg";
import portadaCasaPilo from "@assets/images/projects/residential/casaPilo/portadaCasaPilo.jpg";
import portadaCasaDasPedras from "@assets/images/projects/residential/casaDasPedras/portadaCasaDasPedras.jpg";
import portadaCasaBonsi from "@assets/images/projects/residential/casaBonsi/portadaCasaBonsi.jpg";
import portadaCasaBL from "@assets/images/projects/residential/casaBL/portadaCasaBL.jpg";
import portadaCasaAzurra from "@assets/images/projects/residential/casaAzurra/portadaCasaAzurra.jpg";
import portadaCasaArboles from "@assets/images/projects/residential/casaArboles/portadaCasaArboles.jpg";
import portadaCasaAltillo from "@assets/images/projects/residential/casaAltillo/portadaCasaAltillo.jpg";
import portadaCasaMurray from "@assets/images/projects/residential/casaMurray/portadaCasaMurray.jpg";
import portadaCasaCabra from "@assets/images/projects/residential/casaCabra/portadaCasaCabra.jpg";
import portadaCasaCubos from "@assets/images/projects/residential/casaCubos/portadaCasaCubos.jpg";
import portadaCasaBalcones from "@assets/images/projects/residential/casaBalcones/portadaCasaBalcones.jpg";
import portadaCasaCastelli from "@assets/images/projects/residential/casaCastelli/portadaCasaCastelli.jpg";
import portadaCasaMagallanes from "@assets/images/projects/residential/casaMagallanes/portadaCasaMagallanes.jpg";
import portadaCasaAirhouse from "@assets/images/projects/residential/casaAirhouse/portadaCasaAirhouse.jpg";
import portadaCasaFuegos from "@assets/images/projects/residential/casaFuegos/portadaCasaFuegos.jpg";
import portadaCasaLastra from "@assets/images/projects/residential/casaLastra/portadaCasaLastra.jpg";
import { useNavigate } from "react-router-dom";
import { Routes as RoutesNames } from "@constants/routes";
import { useTranslation } from "react-i18next";
import ProjectCardMobile from "@components/ProjectCardMobile/ProjectCardMobile";
import useGallery from "@hooks/useGallery";

function ResidentialSection() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  const handleOpenCarousel = (project) => () => {
    const projectNameFormatted = project.name.toLowerCase().replace(/\s+/g, "");

    navigate(`/${RoutesNames.Residential}/${projectNameFormatted}`, {
      state: { project },
    });
  };

  const { ShowGallery, handleOpenGallery } = useGallery();

  const isMobile = window.innerWidth <= 1000;

  return (
    <div id="residentialSection" className={styles.residentialContainer}>
      <div className={styles.residentialTitleContainer}>
        <div className={styles.residentialFirstTitle}>
          {t("residential.firstTitle")}
        </div>
        <div className={styles.residentialSecondTitle}>
          {t("residential.secondTitle")}
        </div>
      </div>
      {isMobile ? (
        <>
          {residentialProjects.map((item) => (
            <div
              className={styles.residentialContainerMobile}
              key={item.id}
              onClick={() => handleOpenGallery(item.galleryImages)}
            >
              <ProjectCardMobile
                key={item.id}
                customTitle={
                  i18n.language === "es" || item.customNameEn === null
                    ? item.customNameEs
                    : item.customNameEn
                }
                title={
                  i18n.language === "es" ||
                  item.nameEn === null ||
                  item.nameEn === undefined
                    ? item.name
                    : item.nameEn
                }
                number={(item.id + 1).toString().padStart(2, "0")}
                portada={item.mobilePortada}
                swap={true}
              />
            </div>
          ))}
        </>
      ) : (
        <>
          <div className={styles.residentialProjectsFirstRow}>
            <div
              className={cn(styles.projectCard, styles.projectCardMarginTop)}
            >
              <img
                loading="lazy"
                src={portadaCasaCervantes}
                alt=""
                width={395}
                height={395}
                onClick={handleOpenCarousel(residentialProjects[2])}
              />
              <ImageTitleAndDescription
                customTitle={t("residential.cervantesFirstWord")}
                title="CERVANTES"
                number="03"
                positionY="top"
                positionYValue={415}
              />
            </div>
            <div
              className={cn(styles.projectCard, styles.projectCardMarginTop)}
            >
              <img
                loading="lazy"
                src={portadaCasaBosque}
                alt=""
                width={264}
                height={391}
                onClick={handleOpenCarousel(residentialProjects[1])}
              />
              <ImageTitleAndDescription
                title={t("residential.woodland")}
                number="02"
                positionY="bottom"
                positionYValue={10}
                positionX="left"
                positionXValue={280}
              />
            </div>
            <div className={styles.projectCard}>
              <img
                loading="lazy"
                src={portadaCasaCacho}
                alt=""
                width={373}
                height={269}
                onClick={handleOpenCarousel(residentialProjects[0])}
              />
              <ImageTitleAndDescription
                title="CACHO"
                number="01"
                positionY="top"
                positionYValue={-44}
                positionX="left"
                positionXValue={-165}
              />
            </div>
          </div>
          <div className={styles.residentialProjectsSecondRow}>
            <div className={styles.residentialProjectsSecondRowLeftColumn}>
              <div
                className={cn(styles.projectCard, styles.projectCardMarginTop)}
              >
                <img
                  loading="lazy"
                  src={portadaCasaGutemberg}
                  alt=""
                  width={628}
                  height={329}
                  onClick={handleOpenCarousel(residentialProjects[3])}
                />
                <ImageTitleAndDescription
                  title="GUTENBERG"
                  number="04"
                  positionY="top"
                  positionYValue={345}
                />
              </div>
              <div
                className={cn(
                  styles.projectCard,
                  styles.projectCardMarginTop,
                  styles.projectCardMarginLeft
                )}
              >
                <img
                  loading="lazy"
                  src={portadaCasaPilo}
                  alt=""
                  width={339}
                  height={329}
                  onClick={handleOpenCarousel(residentialProjects[5])}
                />
                <ImageTitleAndDescription
                  title="PILO"
                  number="06"
                  positionY="top"
                  positionYValue={185}
                  positionX="left"
                  positionXValue={-435}
                />
              </div>
              <div
                className={cn(styles.projectCard, styles.projectCardMarginTop)}
              >
                <img
                  loading="lazy"
                  src={portadaCasaDasPedras}
                  alt=""
                  width={768}
                  height={294}
                  onClick={handleOpenCarousel(residentialProjects[7])}
                />
                <ImageTitleAndDescription
                  title="DAS PEDRAS"
                  number="08"
                  positionY="top"
                  positionYValue={315}
                  positionX="right"
                  positionXValue={30}
                />
              </div>
            </div>
            <div className={styles.residentialProjectsSecondRowRightColumn}>
              <div className={styles.projectCard}>
                <img
                  loading="lazy"
                  src={portadaCasaLomadas}
                  alt=""
                  width={361}
                  height={551}
                  onClick={handleOpenCarousel(residentialProjects[4])}
                />
                <ImageTitleAndDescription
                  title="LOMADAS"
                  number="05"
                  positionY="top"
                  positionYValue={580}
                  positionX="right"
                  positionXValue={65}
                />
              </div>
              <div className={cn(styles.projectCard, styles.casaBonsi)}>
                <img
                  loading="lazy"
                  src={portadaCasaBonsi}
                  alt=""
                  width={380}
                  height={426}
                  onClick={handleOpenCarousel(residentialProjects[6])}
                />
                <ImageTitleAndDescription
                  title="BONSI"
                  number="07"
                  positionY="top"
                  positionYValue={450}
                  positionX="right"
                  positionXValue={85}
                />
              </div>
            </div>
          </div>
          <div className={styles.residentialProjectsThirdRow}>
            <div className={styles.residentialProjectsThirdRowLeftColumn}>
              <div className={styles.projectCard}>
                <img
                  loading="lazy"
                  src={portadaCasaBL}
                  alt=""
                  width={276}
                  height={391}
                  onClick={handleOpenCarousel(residentialProjects[8])}
                />
                <ImageTitleAndDescription
                  customTitle={t("residential.blFirstWord")}
                  title="BL"
                  number="09"
                  positionY="top"
                  positionYValue={190}
                  positionX="left"
                  positionXValue={265}
                />
              </div>
              <div
                className={cn(styles.projectCard, styles.projectCardMarginTop)}
              >
                <img
                  loading="lazy"
                  src={portadaCasaArboles}
                  alt=""
                  width={456}
                  height={376}
                  onClick={handleOpenCarousel(residentialProjects[10])}
                />
                <ImageTitleAndDescription
                  title={t("residential.39trees")}
                  number="11"
                  positionY="bottom"
                  positionYValue={-40}
                />
              </div>
              <div
                className={cn(
                  styles.projectCard,
                  styles.projectCardExtraMarginTop
                )}
              >
                <img
                  loading="lazy"
                  src={portadaCasaMurray}
                  alt=""
                  width={546}
                  height={304}
                  onClick={handleOpenCarousel(residentialProjects[12])}
                />
                <ImageTitleAndDescription
                  title="MURRAY"
                  number="13"
                  positionY="bottom"
                  positionYValue={-40}
                />
              </div>
              <div
                className={cn(styles.projectCard, styles.projectCardMarginTop)}
              >
                <img
                  loading="lazy"
                  src={portadaCasaCubos}
                  alt=""
                  width={384}
                  height={572}
                  onClick={handleOpenCarousel(residentialProjects[14])}
                />
                <ImageTitleAndDescription
                  title={t("residential.cubes")}
                  number="15"
                  positionY="top"
                  positionYValue={260}
                  positionX="right"
                  positionXValue={-130}
                />
                <div
                  className={cn(
                    styles.projectCard,
                    styles.projectCardMarginTop
                  )}
                >
                  <img
                    loading="lazy"
                    src={portadaCasaLastra}
                    alt=""
                    width={590}
                    height={357}
                    onClick={handleOpenCarousel(residentialProjects[16])}
                  />
                  <ImageTitleAndDescription
                    customTitle={t("residential.lastraFirstWord")}
                    title="LASTRA"
                    number="17"
                    positionY="bottom"
                    positionYValue={-40}
                  />
                </div>
                <div
                  className={cn(
                    styles.projectCard,
                    styles.projectCardMarginTop
                  )}
                >
                  <img
                    loading="lazy"
                    src={portadaCasaMagallanes}
                    alt=""
                    width={623}
                    height={351}
                    onClick={handleOpenCarousel(residentialProjects[18])}
                  />
                  <ImageTitleAndDescription
                    title="MAGALLANES"
                    number="19"
                    positionY="bottom"
                    positionYValue={-40}
                  />
                </div>
                <div
                  className={cn(
                    styles.projectCard,
                    styles.projectCardMarginTop
                  )}
                >
                  <img
                    loading="lazy"
                    src={portadaCasaFuegos}
                    alt=""
                    width={522}
                    height={522}
                    onClick={handleOpenCarousel(residentialProjects[20])}
                  />
                  <ImageTitleAndDescription
                    title={t("residential.fires")}
                    number="21"
                    positionY="top"
                    positionYValue={260}
                    positionX="right"
                    positionXValue={-290}
                  />
                </div>
              </div>
            </div>
            <div className={styles.residentialProjectsThirdRowRightColumn}>
              <div className={styles.projectCard}>
                <img
                  loading="lazy"
                  src={portadaCasaAzurra}
                  alt=""
                  width={547}
                  height={330}
                  onClick={handleOpenCarousel(residentialProjects[9])}
                />
                <ImageTitleAndDescription
                  title="AZZURRA"
                  number="10"
                  positionY="bottom"
                  positionYValue={-40}
                  positionX="left"
                  positionXValue={-40}
                />
              </div>
              <div className={cn(styles.projectCard, styles.casaAltillo)}>
                <img
                  loading="lazy"
                  src={portadaCasaAltillo}
                  alt=""
                  width={395}
                  height={437}
                  onClick={handleOpenCarousel(residentialProjects[11])}
                />
                <ImageTitleAndDescription
                  customTitle={t("residential.atticFirstWord")}
                  title={t("residential.attic")}
                  number="12"
                  positionY="bottom"
                  positionYValue={-40}
                  positionX="left"
                  positionXValue={-110}
                />
              </div>
              <div
                className={cn(styles.projectCard, styles.projectCardMarginTop)}
              >
                <img
                  loading="lazy"
                  src={portadaCasaCabra}
                  alt=""
                  width={480}
                  height={480}
                  onClick={handleOpenCarousel(residentialProjects[13])}
                />
                <ImageTitleAndDescription
                  title={t("residential.goat")}
                  number="14"
                  positionY="bottom"
                  positionYValue={-40}
                  positionX="left"
                  positionXValue={-40}
                />
              </div>
              <div
                className={cn(styles.projectCard, styles.projectCardMarginTop)}
              >
                <img
                  loading="lazy"
                  src={portadaCasaBalcones}
                  alt=""
                  width={473}
                  height={252}
                  onClick={handleOpenCarousel(residentialProjects[15])}
                />
                <ImageTitleAndDescription
                  customTitle={t("residential.balconiesFirstWord")}
                  title={t("residential.balconies")}
                  number="16"
                  positionY="bottom"
                  positionYValue={-40}
                  positionX="left"
                  positionXValue={-40}
                />
              </div>
              <div
                className={cn(styles.projectCard, styles.projectCardMarginTop)}
              >
                <img
                  loading="lazy"
                  src={portadaCasaCastelli}
                  alt=""
                  width={575}
                  height={310}
                  onClick={handleOpenCarousel(residentialProjects[17])}
                />
                <ImageTitleAndDescription
                  customTitle={t("residential.castelliFirstWord")}
                  title="CASTELLI"
                  number="18"
                  positionY="bottom"
                  positionYValue={-40}
                  positionX="left"
                  positionXValue={-40}
                />
              </div>
              <div
                className={cn(
                  styles.projectCard,
                  styles.projectCardExtraMarginTop
                )}
              >
                <img
                  loading="lazy"
                  src={portadaCasaAirhouse}
                  alt=""
                  width={402}
                  height={570}
                  onClick={handleOpenCarousel(residentialProjects[19])}
                />
                <ImageTitleAndDescription
                  customTitle={t("residential.arihouseFirstWord")}
                  title="ARIHOUSE"
                  number="20"
                  positionY="bottom"
                  positionYValue={-40}
                  positionX="left"
                  positionXValue={-200}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <ShowGallery />
    </div>
  );
}

export default ResidentialSection;
