import React from "react";
import styles from "./ExperimentationsSection.module.scss";
import experimentation1 from "@assets/images/experimentations/Experimentacion_1.jpg";
import experimentation2 from "@assets/images/experimentations/Experimentacion_2.jpg";
import atmosphere from "@assets/images/experimentations/Atmosfera.jpg";
import material1 from "@assets/images/experimentations/Materialidad_1.jpg";
import material2 from "@assets/images/experimentations/Materialidad_2.jpg";
import form from "@assets/images/experimentations/Forma.jpg";
import time1 from "@assets/images/experimentations/Tiempo_1.jpg";
import time2 from "@assets/images/experimentations/Tiempo_2.jpg";
import time3 from "@assets/images/experimentations/Tiempo_3.jpg";
import time4 from "@assets/images/experimentations/Tiempo_4.jpg";
import time5 from "@assets/images/experimentations/Tiempo_5.jpg";
import { useTranslation } from "react-i18next";

const ExperimentationsSection = () => {
  const { t } = useTranslation("global");
  return (
    <div
      id="experimentationsSection"
      className={styles.experimentationsSection}
    >
      <div className={styles.titleContainer}>
        <p className={styles.heroHeaderTextMedium}>studio</p>
        <p className={styles.heroHeaderTextSpacing}>materials</p>
        <p className={styles.heroHeaderTextMedium}>dynamic</p>
        <p className={styles.heroHeaderTextSpacing}>organic</p>
      </div>
      <div className={styles.natureContainer}>
        <img src={experimentation1} alt="Experimentacion 1" />
        <img src={experimentation2} alt="Experimentacion 2" />
        <div className={styles.natureText}>
          <p>{t("experimentation.natureTitle")}</p>
          <p>{t("experimentation.natureText")}</p>
        </div>
      </div>
      <div className={styles.atmosphereContainer}>
        <img src={atmosphere} alt="Atmosfera" />
        <div className={styles.atmosphereText}>
          <p>{t("experimentation.atmosphereTitle")}</p>
          <p>{t("experimentation.atmosphereFirstText")}</p>
          <p>{t("experimentation.atmosphereSecondText")}</p>
        </div>
      </div>
      <div className={styles.materialContainer}>
        <div className={styles.materialText}>
          <p>{t("experimentation.materialTitle")}</p>
          <p>{t("experimentation.materialText")}</p>
        </div>
        <img
          src={material1}
          alt="Materialidad 1"
          className={styles.materialLeftImg}
        />
        <img
          src={material2}
          alt="Materialidad 2"
          className={styles.materialRightImg}
        />
      </div>
      <div className={styles.formContainer}>
        <img src={form} alt="Forma" />
        <div className={styles.formText}>
          <p>{t("experimentation.formTitle")}</p>
          <p>{t("experimentation.formText")}</p>
        </div>
      </div>
      <div className={styles.timeSection}>
        <div className={styles.timeFirstContainer}>
          <div className={styles.timeFirstContainerText}>
            <p>{t("experimentation.timeFirstText")}</p>
          </div>
          <div className={styles.timeFirstContainerImgContainer}>
            <img src={time1} alt="Tiempo_1" style={{ marginRight: "20px" }} />
          </div>
          <div className={styles.timeFirstContainerTitle}>
            <p>{t("experimentation.timeTitle")}</p>
          </div>
        </div>
        <div className={styles.timeSecondContainer}>
          <img src={time2} alt="Tiempo_2" />
        </div>
        <div className={styles.timeThirdContainer}>
          <img src={time3} alt="Tiempo_3" />
          <img src={time4} alt="Tiempo_4" />
          <div className={styles.timeThirdContainerText}>
            <p>{t("experimentation.timeSecondText")}</p>
          </div>
        </div>
        <div className={styles.timeFourthContainer}>
          <div className={styles.timeFourthContainerText}>
            <p>{t("experimentation.timeThirdText")}</p>
            <p>{t("experimentation.timeFourthText")}</p>
          </div>
        </div>
        <div className={styles.timeFifthContainer}>
          <img src={time5} alt="Tiempo_5" />
        </div>
      </div>
    </div>
  );
};

export default ExperimentationsSection;
