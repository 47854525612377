import React, { useState, useEffect } from "react";
import styles from "./HeroSection.module.scss";
import { Routes as RoutesNames } from "@constants/routes";
import { Link } from "react-router-dom";
import { scrollToTop } from "@utils/scrollToTop";
import { useTranslation } from "react-i18next";

import spanishHeroGif from "@assets/images/backgrounds/spanishHeroGif.gif";
import spanishHeroHover from "@assets/images/backgrounds/spanishHeroHover.jpg";
import englishHeroGif from "@assets/images/backgrounds/englishHeroGif.gif";
import englishHeroHover from "@assets/images/backgrounds/englishHeroHover.jpg";
import englishHeroHoverMobile from "@assets/images/backgrounds/mobile/englishHeroGifMobile.gif";
import spanishHeroGifMobile from "@assets/images/backgrounds/mobile/spanishHeroGifMobile.gif";

function HeroSection() {
  const [isHovered, setIsHovered] = useState(false);
  const { i18n } = useTranslation("global");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const spanishHeroImage =
    i18n.language === "es" ? spanishHeroGif : englishHeroGif;
  const spanishHeroHoverImage =
    i18n.language === "es" ? spanishHeroHover : englishHeroHover;

  const mobileHeroImage =
    i18n.language === "es" ? spanishHeroGifMobile : englishHeroHoverMobile;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  let heroImage;
  if (isHovered) {
    heroImage = <img src={spanishHeroHoverImage} alt="Hero section" />;
  } else {
    heroImage = (
      <img
        src={windowWidth < 768 ? mobileHeroImage : spanishHeroImage}
        alt="Hero section Gif"
      />
    );
  }

  return (
    <div className={styles.heroSection}>
      <div className={styles.heroHeader}>
        <p className={styles.heroHeaderTextMedium}>studio</p>
        <p className={styles.heroHeaderTextSpacing}>materials</p>
        <p className={styles.heroHeaderTextMedium}>dynamic</p>
        <p className={styles.heroHeaderTextSpacing}>organic</p>
      </div>
      <div className={styles.contenedor}>
        <div className={styles.divAzul}></div>
        <div className={styles.divRojo}></div>
      </div>
      <div className={styles.backgroundHero}>
        {heroImage}
        <Link
          className={styles.overlay}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={scrollToTop}
          to={RoutesNames.Experimentations}
        />
      </div>
    </div>
  );
}

export default HeroSection;
