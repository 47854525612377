import styles from "./SlickCarousel.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useGallery from "@hooks/useGallery";
import { useTranslation } from "react-i18next";

const SlickCarousel = ({ carouselImages, galleryImages }) => {
  const { i18n } = useTranslation("global");
  const { ShowGallery, handleOpenGallery, autoplay } = useGallery();

  const openGalleryText =
    i18n.language === "es" ? "Abrir Galería" : "Open Gallery";

  const sliderProps = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: autoplay,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className={styles.carouselContainer}>
        <Slider {...sliderProps}>
          {carouselImages.map((image, index) => (
            <div key={index} className={styles.carouselImageContainer}>
              <img
                src={image}
                alt={`Slide ${index}`}
                className={styles.carouselImage}
                onClick={() => handleOpenGallery(galleryImages)}
              />
            </div>
          ))}
        </Slider>
        <div
          className={styles.galleryButton}
          onClick={() => handleOpenGallery(galleryImages)}
        >
          <div className={styles.openGalleryText}>{openGalleryText}</div>
        </div>
      </div>
      <ShowGallery />
    </>
  );
};

export default SlickCarousel;
