//TODO: todas los comentarios son por imagenes faltantes de parte del diseñador, lo dejamos comentado para cuando envie el total de las imagenes implementarlas
import casaCacho1 from "@assets/images/projects/residential/casaCacho/gallery/casaCacho1.jpg";
import casaCacho2 from "@assets/images/projects/residential/casaCacho/gallery/casaCacho2.jpg";
import casaCacho3 from "@assets/images/projects/residential/casaCacho/gallery/casaCacho3.jpg";
import casaCacho4 from "@assets/images/projects/residential/casaCacho/gallery/casaCacho4.jpg";
import casaBosque1 from "@assets/images/projects/residential/casaBosque/gallery/casaBosque1.jpg";
import casaBosque2 from "@assets/images/projects/residential/casaBosque/gallery/casaBosque2.jpg";
import casaBosque3 from "@assets/images/projects/residential/casaBosque/gallery/casaBosque3.jpg";
import casaBosque4 from "@assets/images/projects/residential/casaBosque/gallery/casaBosque4.jpg";
import casaCervantes1 from "@assets/images/projects/residential/casaCervantes/gallery/casaCervantes1.jpg";
import casaCervantes2 from "@assets/images/projects/residential/casaCervantes/gallery/casaCervantes2.jpg";
import casaCervantes3 from "@assets/images/projects/residential/casaCervantes/gallery/casaCervantes3.jpg";
import casaCervantes4 from "@assets/images/projects/residential/casaCervantes/gallery/casaCervantes4.jpg";
import casaCervantes5 from "@assets/images/projects/residential/casaCervantes/gallery/casaCervantes5.jpg";
import casaGutemberg1 from "@assets/images/projects/residential/casaGutemberg/gallery/casaGutemberg1.jpg";
import casaGutemberg2 from "@assets/images/projects/residential/casaGutemberg/gallery/casaGutemberg2.jpg";
import casaGutemberg3 from "@assets/images/projects/residential/casaGutemberg/gallery/casaGutemberg3.jpg";
import casaGutemberg4 from "@assets/images/projects/residential/casaGutemberg/gallery/casaGutemberg4.jpg";
import casaLomadas1 from "@assets/images/projects/residential/casaLomadas/gallery/casaLomadas1.jpg";
import casaLomadas2 from "@assets/images/projects/residential/casaLomadas/gallery/casaLomadas2.jpg";
import casaLomadas3 from "@assets/images/projects/residential/casaLomadas/gallery/casaLomadas3.jpg";
import casaLomadas4 from "@assets/images/projects/residential/casaLomadas/gallery/casaLomadas4.jpg";
import casaLomadas5 from "@assets/images/projects/residential/casaLomadas/gallery/casaLomadas5.jpg";
import casaPilo1 from "@assets/images/projects/residential/casaPilo/gallery/casaPilo1.jpg";
import casaPilo2 from "@assets/images/projects/residential/casaPilo/gallery/casaPilo2.jpg";
import casaPilo3 from "@assets/images/projects/residential/casaPilo/gallery/casaPilo3.jpg";
import casaPilo4 from "@assets/images/projects/residential/casaPilo/gallery/casaPilo4.jpg";
import casaDasPedras1 from "@assets/images/projects/residential/casaDasPedras/gallery/casaDasPedras1.jpg";
import casaDasPedras2 from "@assets/images/projects/residential/casaDasPedras/gallery/casaDasPedras2.jpg";
import casaDasPedras3 from "@assets/images/projects/residential/casaDasPedras/gallery/casaDasPedras3.jpg";
import casaBonsi1 from "@assets/images/projects/residential/casaBonsi/gallery/casaBonsi1.jpg";
import casaBonsi2 from "@assets/images/projects/residential/casaBonsi/gallery/casaBonsi2.jpg";
import casaBonsi3 from "@assets/images/projects/residential/casaBonsi/gallery/casaBonsi3.jpg";
import casaBL1 from "@assets/images/projects/residential/casaBL/gallery/casaBL1.jpg";
import casaBL2 from "@assets/images/projects/residential/casaBL/gallery/casaBL2.jpg";
import casaBL3 from "@assets/images/projects/residential/casaBL/gallery/casaBL3.jpg";
import casaBL4 from "@assets/images/projects/residential/casaBL/gallery/casaBL4.jpg";
import casaAzurra1 from "@assets/images/projects/residential/casaAzurra/gallery/casaAzurra1.jpg";
import casaAzurra2 from "@assets/images/projects/residential/casaAzurra/gallery/casaAzurra2.jpg";
import casaAzurra3 from "@assets/images/projects/residential/casaAzurra/gallery/casaAzurra3.jpg";
import casaAzurra4 from "@assets/images/projects/residential/casaAzurra/gallery/casaAzurra4.jpg";
import casaArboles1 from "@assets/images/projects/residential/casaArboles/gallery/casaArboles1.jpg";
import casaArboles2 from "@assets/images/projects/residential/casaArboles/gallery/casaArboles2.jpg";
import casaArboles3 from "@assets/images/projects/residential/casaArboles/gallery/casaArboles3.jpg";
import casaArboles4 from "@assets/images/projects/residential/casaArboles/gallery/casaArboles4.jpg";
import casaArboles5 from "@assets/images/projects/residential/casaArboles/gallery/casaArboles5.jpg";
import casaArboles6 from "@assets/images/projects/residential/casaArboles/gallery/casaArboles6.jpg";
import casaArboles7 from "@assets/images/projects/residential/casaArboles/gallery/casaArboles7.jpg";
import casaArboles8 from "@assets/images/projects/residential/casaArboles/gallery/casaArboles8.jpg";
import casaArboles9 from "@assets/images/projects/residential/casaArboles/gallery/casaArboles9.jpg";
import casaAltillo1 from "@assets/images/projects/residential/casaAltillo/gallery/casaAltillo1.jpg";
import casaAltillo2 from "@assets/images/projects/residential/casaAltillo/gallery/casaAltillo2.jpg";
import casaAltillo3 from "@assets/images/projects/residential/casaAltillo/gallery/casaAltillo3.jpg";
import casaAltillo4 from "@assets/images/projects/residential/casaAltillo/gallery/casaAltillo4.jpg";
import casaMurray1 from "@assets/images/projects/residential/casaMurray/gallery/casaMurray1.jpg";
import casaMurray2 from "@assets/images/projects/residential/casaMurray/gallery/casaMurray2.jpg";
import casaMurray3 from "@assets/images/projects/residential/casaMurray/gallery/casaMurray3.jpg";
import casaMurray4 from "@assets/images/projects/residential/casaMurray/gallery/casaMurray4.jpg";
import casaCabra1 from "@assets/images/projects/residential/casaCabra/gallery/casaCabra1.jpg";
import casaCabra2 from "@assets/images/projects/residential/casaCabra/gallery/casaCabra2.jpg";
import casaCabra3 from "@assets/images/projects/residential/casaCabra/gallery/casaCabra3.jpg";
import casaCabra4 from "@assets/images/projects/residential/casaCabra/gallery/casaCabra4.jpg";
import casaCubos1 from "@assets/images/projects/residential/casaCubos/gallery/casaCubos1.jpg";
import casaCubos2 from "@assets/images/projects/residential/casaCubos/gallery/casaCubos2.jpg";
import casaCubos3 from "@assets/images/projects/residential/casaCubos/gallery/casaCubos3.jpg";
import casaCubos4 from "@assets/images/projects/residential/casaCubos/gallery/casaCubos4.jpg";
import casaBalcones1 from "@assets/images/projects/residential/casaBalcones/gallery/casaBalcones1.jpg";
import casaBalcones2 from "@assets/images/projects/residential/casaBalcones/gallery/casaBalcones2.jpg";
import casaBalcones3 from "@assets/images/projects/residential/casaBalcones/gallery/casaBalcones3.jpg";
import casaBalcones4 from "@assets/images/projects/residential/casaBalcones/gallery/casaBalcones4.jpg";
import casaCastelli1 from "@assets/images/projects/residential/casaCastelli/gallery/casaCastelli1.jpg";
import casaCastelli2 from "@assets/images/projects/residential/casaCastelli/gallery/casaCastelli2.jpg";
import casaCastelli3 from "@assets/images/projects/residential/casaCastelli/gallery/casaCastelli3.jpg";
import casaCastelli4 from "@assets/images/projects/residential/casaCastelli/gallery/casaCastelli4.jpg";
import casaMagallanes1 from "@assets/images/projects/residential/casaMagallanes/gallery/casaMagallanes1.jpg";
import casaMagallanes2 from "@assets/images/projects/residential/casaMagallanes/gallery/casaMagallanes2.jpg";
import casaMagallanes3 from "@assets/images/projects/residential/casaMagallanes/gallery/casaMagallanes3.jpg";
import casaAirhouse1 from "@assets/images/projects/residential/casaAirhouse/gallery/casaAirhouse1.jpg";
import casaAirhouse2 from "@assets/images/projects/residential/casaAirhouse/gallery/casaAirhouse2.jpg";
import casaAirhouse3 from "@assets/images/projects/residential/casaAirhouse/gallery/casaAirhouse3.jpg";
import casaFuegos1 from "@assets/images/projects/residential/casaFuegos/gallery/casaFuegos1.jpg";
import casaFuegos2 from "@assets/images/projects/residential/casaFuegos/gallery/casaFuegos2.jpg";
import casaFuegos3 from "@assets/images/projects/residential/casaFuegos/gallery/casaFuegos3.jpg";
import casaFuegos4 from "@assets/images/projects/residential/casaFuegos/gallery/casaFuegos4.jpg";
import casaLastra1 from "@assets/images/projects/residential/casaLastra/gallery/casaLastra1.jpg";
import casaLastra2 from "@assets/images/projects/residential/casaLastra/gallery/casaLastra2.jpg";
import casaLastra3 from "@assets/images/projects/residential/casaLastra/gallery/casaLastra3.jpg";
import casaLastra4 from "@assets/images/projects/residential/casaLastra/gallery/casaLastra4.jpg";

//Carousel Images
import casaCachoCarousel1 from "@assets/images/projects/residential/casaCacho/carousel/1.jpg";
import casaCachoCarousel2 from "@assets/images/projects/residential/casaCacho/carousel/2.jpg";
import casaCachoCarousel3 from "@assets/images/projects/residential/casaCacho/carousel/3.jpg";
import casaCachoCarousel4 from "@assets/images/projects/residential/casaCacho/carousel/4.jpg";
import casaBosqueCarousel1 from "@assets/images/projects/residential/casaBosque/carousel/1.jpg";
import casaBosqueCarousel2 from "@assets/images/projects/residential/casaBosque/carousel/2.jpg";
import casaBosqueCarousel3 from "@assets/images/projects/residential/casaBosque/carousel/3.jpg";
import casaBosqueCarousel4 from "@assets/images/projects/residential/casaBosque/carousel/4.jpg";
import casaCervantesCarousel1 from "@assets/images/projects/residential/casaCervantes/carousel/1.jpg";
import casaCervantesCarousel2 from "@assets/images/projects/residential/casaCervantes/carousel/2.jpg";
import casaCervantesCarousel3 from "@assets/images/projects/residential/casaCervantes/carousel/3.jpg";
import casaCervantesCarousel4 from "@assets/images/projects/residential/casaCervantes/carousel/4.jpg";
import casaCervantesCarousel5 from "@assets/images/projects/residential/casaCervantes/carousel/5.jpg";
import casaGutembergCarousel1 from "@assets/images/projects/residential/casaGutemberg/carousel/1.jpg";
import casaGutembergCarousel2 from "@assets/images/projects/residential/casaGutemberg/carousel/2.jpg";
import casaGutembergCarousel3 from "@assets/images/projects/residential/casaGutemberg/carousel/3.jpg";
import casaLomadasCarousel1 from "@assets/images/projects/residential/casaLomadas/carousel/1.jpg";
import casaLomadasCarousel2 from "@assets/images/projects/residential/casaLomadas/carousel/2.jpg";
import casaLomadasCarousel3 from "@assets/images/projects/residential/casaLomadas/carousel/3.jpg";
import casaLomadasCarousel4 from "@assets/images/projects/residential/casaLomadas/carousel/4.jpg";
import casaPiloCarousel1 from "@assets/images/projects/residential/casaPilo/carousel/1.jpg";
import casaPiloCarousel2 from "@assets/images/projects/residential/casaPilo/carousel/2.jpg";
import casaPiloCarousel3 from "@assets/images/projects/residential/casaPilo/carousel/3.jpg";
import casaPiloCarousel4 from "@assets/images/projects/residential/casaPilo/carousel/4.jpg";
import casaDasPedrasCarousel1 from "@assets/images/projects/residential/casaDasPedras/carousel/1.jpg";
import casaDasPedrasCarousel2 from "@assets/images/projects/residential/casaDasPedras/carousel/2.jpg";
import casaDasPedrasCarousel3 from "@assets/images/projects/residential/casaDasPedras/carousel/3.jpg";
import casaDasPedrasCarousel4 from "@assets/images/projects/residential/casaDasPedras/carousel/4.jpg";
import casaBonsiCarousel1 from "@assets/images/projects/residential/casaBonsi/carousel/1.jpg";
import casaBonsiCarousel2 from "@assets/images/projects/residential/casaBonsi/carousel/2.jpg";
import casaBonsiCarousel3 from "@assets/images/projects/residential/casaBonsi/carousel/3.jpg";
import casaBonsiCarousel4 from "@assets/images/projects/residential/casaBonsi/carousel/4.jpg";
import casaBLCarousel1 from "@assets/images/projects/residential/casaBL/carousel/1.jpg";
import casaBLCarousel2 from "@assets/images/projects/residential/casaBL/carousel/2.jpg";
import casaBLCarousel3 from "@assets/images/projects/residential/casaBL/carousel/3.jpg";
import casaBLCarousel4 from "@assets/images/projects/residential/casaBL/carousel/4.jpg";
import casaAzurraCarousel1 from "@assets/images/projects/residential/casaAzurra/carousel/1.jpg";
import casaAzurraCarousel2 from "@assets/images/projects/residential/casaAzurra/carousel/2.jpg";
import casaAzurraCarousel3 from "@assets/images/projects/residential/casaAzurra/carousel/3.jpg";
import casaAzurraCarousel4 from "@assets/images/projects/residential/casaAzurra/carousel/4.jpg";
import casaArbolesCarousel1 from "@assets/images/projects/residential/casaArboles/carousel/1.jpg";
import casaArbolesCarousel2 from "@assets/images/projects/residential/casaArboles/carousel/2.jpg";
import casaArbolesCarousel3 from "@assets/images/projects/residential/casaArboles/carousel/3.jpg";
import casaArbolesCarousel4 from "@assets/images/projects/residential/casaArboles/carousel/4.jpg";
import casaArbolesCarousel5 from "@assets/images/projects/residential/casaArboles/carousel/5.jpg";
import casaArbolesCarousel6 from "@assets/images/projects/residential/casaArboles/carousel/6.jpg";
import casaArbolesCarousel7 from "@assets/images/projects/residential/casaArboles/carousel/7.jpg";
import casaArbolesCarousel8 from "@assets/images/projects/residential/casaArboles/carousel/8.jpg";
import casaArbolesCarousel9 from "@assets/images/projects/residential/casaArboles/carousel/9.jpg";
import casaAltilloCarousel1 from "@assets/images/projects/residential/casaAltillo/carousel/1.jpg";
import casaAltilloCarousel2 from "@assets/images/projects/residential/casaAltillo/carousel/2.jpg";
import casaAltilloCarousel3 from "@assets/images/projects/residential/casaAltillo/carousel/3.jpg";
import casaAltilloCarousel4 from "@assets/images/projects/residential/casaAltillo/carousel/4.jpg";
import casaMurrayCarousel1 from "@assets/images/projects/residential/casaMurray/carousel/1.jpg";
import casaMurrayCarousel2 from "@assets/images/projects/residential/casaMurray/carousel/2.jpg";
import casaMurrayCarousel3 from "@assets/images/projects/residential/casaMurray/carousel/3.jpg";
import casaMurrayCarousel4 from "@assets/images/projects/residential/casaMurray/carousel/4.jpg";
import casaCabraCarousel1 from "@assets/images/projects/residential/casaCabra/carousel/1.jpg";
import casaCabraCarousel2 from "@assets/images/projects/residential/casaCabra/carousel/2.jpg";
import casaCabraCarousel3 from "@assets/images/projects/residential/casaCabra/carousel/3.jpg";
import casaCabraCarousel4 from "@assets/images/projects/residential/casaCabra/carousel/4.jpg";
import casaCubosCarousel1 from "@assets/images/projects/residential/casaCubos/carousel/1.jpg";
import casaCubosCarousel2 from "@assets/images/projects/residential/casaCubos/carousel/2.jpg";
import casaCubosCarousel3 from "@assets/images/projects/residential/casaCubos/carousel/3.jpg";
import casaCubosCarousel4 from "@assets/images/projects/residential/casaCubos/carousel/4.jpg";
import casaBalconesCarousel1 from "@assets/images/projects/residential/casaBalcones/carousel/1.jpg";
import casaBalconesCarousel2 from "@assets/images/projects/residential/casaBalcones/carousel/2.jpg";
import casaBalconesCarousel3 from "@assets/images/projects/residential/casaBalcones/carousel/3.jpg";
import casaCastelliCarousel1 from "@assets/images/projects/residential/casaCastelli/carousel/1.jpg";
import casaCastelliCarousel2 from "@assets/images/projects/residential/casaCastelli/carousel/2.jpg";
import casaCastelliCarousel3 from "@assets/images/projects/residential/casaCastelli/carousel/3.jpg";
import casaCastelliCarousel4 from "@assets/images/projects/residential/casaCastelli/carousel/4.jpg";
import casaMagallanesCarousel1 from "@assets/images/projects/residential/casaMagallanes/carousel/1.jpg";
import casaMagallanesCarousel2 from "@assets/images/projects/residential/casaMagallanes/carousel/2.jpg";
import casaMagallanesCarousel3 from "@assets/images/projects/residential/casaMagallanes/carousel/3.jpg";
import casaAirhouseCarousel1 from "@assets/images/projects/residential/casaAirhouse/carousel/1.jpg";
import casaAirhouseCarousel2 from "@assets/images/projects/residential/casaAirhouse/carousel/2.jpg";
import casaAirhouseCarousel3 from "@assets/images/projects/residential/casaAirhouse/carousel/3.jpg";
import casaFuegosCarousel1 from "@assets/images/projects/residential/casaFuegos/carousel/1.jpg";
import casaFuegosCarousel2 from "@assets/images/projects/residential/casaFuegos/carousel/2.jpg";
import casaFuegosCarousel3 from "@assets/images/projects/residential/casaFuegos/carousel/3.jpg";
import casaFuegosCarousel4 from "@assets/images/projects/residential/casaFuegos/carousel/4.jpg";
import casaLastraCarousel1 from "@assets/images/projects/residential/casaLastra/carousel/1.jpg";
import casaLastraCarousel2 from "@assets/images/projects/residential/casaLastra/carousel/2.jpg";
import casaLastraCarousel3 from "@assets/images/projects/residential/casaLastra/carousel/3.jpg";
import casaLastraCarousel4 from "@assets/images/projects/residential/casaLastra/carousel/4.jpg";

//Mobile Images
import casaCachoPortadaMobile from "@assets/images/projects/mobile/residential/casaCachoPortadaMobile.jpg";
import casaBosquePortadaMobile from "@assets/images/projects/mobile/residential/casaBosquePortadaMobile.jpg";
import casaCervantesPortadaMobile from "@assets/images/projects/mobile/residential/casaCervantesPortadaMobile.jpg";
import casaGutembergPortadaMobile from "@assets/images/projects/mobile/residential/casaGutembergPortadaMobile.jpg";
import casaLomadasPortadaMobile from "@assets/images/projects/mobile/residential/casaLomadasPortadaMobile.jpg";
import casaPiloPortadaMobile from "@assets/images/projects/mobile/residential/casaPiloPortadaMobile.jpg";
import casaDasPedrasPortadaMobile from "@assets/images/projects/mobile/residential/casaDasPedrasPortadaMobile.jpg";
import casaBonsiPortadaMobile from "@assets/images/projects/mobile/residential/casaBonsiPortadaMobile.jpg";
import casaBLPortadaMobile from "@assets/images/projects/mobile/residential/casaBLPortadaMobile.jpg";
import casaAzurraPortadaMobile from "@assets/images/projects/mobile/residential/casaAzurraPortadaMobile.jpg";
import casaArbolesPortadaMobile from "@assets/images/projects/mobile/residential/casaArbolesPortadaMobile.jpg";
import casaAltilloPortadaMobile from "@assets/images/projects/mobile/residential/casaAltilloPortadaMobile.jpg";
import casaMurrayPortadaMobile from "@assets/images/projects/mobile/residential/casaMurrayPortadaMobile.jpg";
import casaCabraPortadaMobile from "@assets/images/projects/mobile/residential/casaCabraPortadaMobile.jpg";
import casaCubosPortadaMobile from "@assets/images/projects/mobile/residential/casaCubosPortadaMobile.jpg";
import casaBalconesPortadaMobile from "@assets/images/projects/mobile/residential/casaBalconesPortadaMobile.jpg";
import casaCastelliPortadaMobile from "@assets/images/projects/mobile/residential/casaCastelliPortadaMobile.jpg";
import casaMagallanesPortadaMobile from "@assets/images/projects/mobile/residential/casaMagallanesPortadaMobile.jpg";
import casaAirhousePortadaMobile from "@assets/images/projects/mobile/residential/casaAirhousePortadaMobile.jpg";
import casaFuegosPortadaMobile from "@assets/images/projects/mobile/residential/casaFuegosPortadaMobile.jpg";
import casaLastraPortadaMobile from "@assets/images/projects/mobile/residential/casaLastraPortadaMobile.jpg";

const residentialProjects = [
  {
    id: 0,
    name: "CACHO",
    carouselNameEs: "CASA CACHO",
    carouselNameEn: "CACHO HOUSE",
    mobilePortada: casaCachoPortadaMobile,
    galleryImages: [casaCacho1, casaCacho2, casaCacho3, casaCacho4],
    carouselImages: [
      casaCachoCarousel1,
      casaCachoCarousel2,
      casaCachoCarousel3,
      casaCachoCarousel4,
    ],
  },
  {
    id: 1,
    name: "BOSQUE",
    carouselNameEs: "EDIFICIO BOSQUE",
    carouselNameEn: "WOODLAND BUILDING",
    nameEn: "WOODLAND",
    customNameEs: "EDIFICIO",
    customNameEn: "BUILDING",
    mobilePortada: casaBosquePortadaMobile,
    galleryImages: [casaBosque1, casaBosque2, casaBosque3, casaBosque4],
    carouselImages: [
      casaBosqueCarousel1,
      casaBosqueCarousel2,
      casaBosqueCarousel3,
      casaBosqueCarousel4,
    ],
  },
  {
    id: 2,
    name: "CERVANTES",
    carouselNameEs: "EDIFICIO CERVANTES",
    carouselNameEn: "CERVANTES BUILDING",
    customNameEs: "EDIFICIO",
    customNameEn: "BUILDING",
    mobilePortada: casaCervantesPortadaMobile,
    galleryImages: [
      casaCervantes1,
      casaCervantes2,
      casaCervantes3,
      casaCervantes4,
      casaCervantes5,
    ],
    carouselImages: [
      casaCervantesCarousel1,
      casaCervantesCarousel2,
      casaCervantesCarousel3,
      casaCervantesCarousel4,
      casaCervantesCarousel5,
    ],
  },
  {
    id: 3,
    name: "GUTENBERG",
    carouselNameEs: "CASA GUTENBERG",
    carouselNameEn: "GUTENBERG HOUSE",
    mobilePortada: casaGutembergPortadaMobile,
    galleryImages: [
      casaGutemberg1,
      casaGutemberg2,
      casaGutemberg3,
      casaGutemberg4,
    ],
    carouselImages: [
      casaGutembergCarousel1,
      casaGutembergCarousel2,
      casaGutembergCarousel3,
    ],
  },
  {
    id: 4,
    name: "LOMADAS",
    carouselNameEs: "CASA LOMADAS",
    carouselNameEn: "LOMADAS HOUSE",
    mobilePortada: casaLomadasPortadaMobile,
    galleryImages: [
      casaLomadas1,
      casaLomadas2,
      casaLomadas3,
      casaLomadas4,
      casaLomadas5,
    ],
    carouselImages: [
      casaLomadasCarousel1,
      casaLomadasCarousel2,
      casaLomadasCarousel3,
      casaLomadasCarousel4,
    ],
  },
  {
    id: 5,
    name: "PILO",
    carouselNameEs: "CASA PILO",
    carouselNameEn: "PILO HOUSE",
    mobilePortada: casaPiloPortadaMobile,
    galleryImages: [casaPilo1, casaPilo2, casaPilo3, casaPilo4],
    carouselImages: [
      casaPiloCarousel1,
      casaPiloCarousel2,
      casaPiloCarousel3,
      casaPiloCarousel4,
    ],
  },
  {
    id: 6,
    name: "BONSI",
    carouselNameEs: "CASA BONSI",
    carouselNameEn: "BONSI HOUSE",
    mobilePortada: casaBonsiPortadaMobile,
    galleryImages: [casaBonsi1, casaBonsi2, casaBonsi3],
    carouselImages: [
      casaBonsiCarousel1,
      casaBonsiCarousel2,
      casaBonsiCarousel3,
      casaBonsiCarousel4,
    ],
  },
  {
    id: 7,
    name: "DAS PEDRAS",
    carouselNameEs: "CASA DAS PEDRAS",
    carouselNameEn: "DAS PEDRAS HOUSE",
    mobilePortada: casaDasPedrasPortadaMobile,
    galleryImages: [casaDasPedras1, casaDasPedras2, casaDasPedras3],
    carouselImages: [
      casaDasPedrasCarousel1,
      casaDasPedrasCarousel2,
      casaDasPedrasCarousel3,
      casaDasPedrasCarousel4,
    ],
  },
  {
    id: 8,
    name: "BL",
    carouselNameEs: "REFORMA BL",
    carouselNameEn: "BL REFORM",
    customNameEs: "REFORMA",
    customNameEn: "REFORM",
    mobilePortada: casaBLPortadaMobile,
    galleryImages: [casaBL1, casaBL2, casaBL3, casaBL4],
    carouselImages: [
      casaBLCarousel1,
      casaBLCarousel2,
      casaBLCarousel3,
      casaBLCarousel4,
    ],
  },
  {
    id: 9,
    name: "AZZURRA",
    carouselNameEs: "CASA AZZURRA",
    carouselNameEn: "AZZURRA HOUSE",
    mobilePortada: casaAzurraPortadaMobile,
    galleryImages: [casaAzurra1, casaAzurra2, casaAzurra3, casaAzurra4],
    carouselImages: [
      casaAzurraCarousel1,
      casaAzurraCarousel2,
      casaAzurraCarousel3,
      casaAzurraCarousel4,
    ],
  },
  {
    id: 10,
    name: "39 ARBOLES",
    carouselNameEs: "CASA 39 ARBOLES",
    carouselNameEn: "39 TREES HOUSE",
    nameEn: "39 TREES",
    mobilePortada: casaArbolesPortadaMobile,
    galleryImages: [
      casaArboles1,
      casaArboles2,
      casaArboles3,
      casaArboles4,
      casaArboles5,
      casaArboles6,
      casaArboles7,
      casaArboles8,
      casaArboles9,
    ],
    carouselImages: [
      casaArbolesCarousel1,
      casaArbolesCarousel2,
      casaArbolesCarousel3,
      casaArbolesCarousel4,
      casaArbolesCarousel5,
      casaArbolesCarousel6,
      casaArbolesCarousel7,
      casaArbolesCarousel8,
      casaArbolesCarousel9,
    ],
  },
  {
    id: 11,
    name: "ALTILLO",
    carouselNameEs: "REFORMA ALTILLO",
    carouselNameEn: "ALTILLO REFORM",
    nameEn: "ATTIC",
    customNameEs: "REFORMA",
    customNameEn: "REFORM",
    mobilePortada: casaAltilloPortadaMobile,
    galleryImages: [casaAltillo1, casaAltillo2, casaAltillo3, casaAltillo4],
    carouselImages: [
      casaAltilloCarousel1,
      casaAltilloCarousel2,
      casaAltilloCarousel3,
      casaAltilloCarousel4,
    ],
  },
  {
    id: 12,
    name: "MURRAY",
    carouselNameEs: "CASA MURRAY",
    carouselNameEn: "MURRAY HOUSE",
    mobilePortada: casaMurrayPortadaMobile,
    galleryImages: [casaMurray1, casaMurray2, casaMurray3, casaMurray4],
    carouselImages: [
      casaMurrayCarousel1,
      casaMurrayCarousel2,
      casaMurrayCarousel3,
      casaMurrayCarousel4,
    ],
  },
  {
    id: 13,
    name: "CABRA",
    carouselNameEs: "CASA CABRA",
    carouselNameEn: "GOAT HOUSE",
    nameEn: "GOAT",
    mobilePortada: casaCabraPortadaMobile,
    galleryImages: [casaCabra1, casaCabra2, casaCabra3, casaCabra4],
    carouselImages: [
      casaCabraCarousel1,
      casaCabraCarousel2,
      casaCabraCarousel3,
      casaCabraCarousel4,
    ],
  },
  {
    id: 14,
    name: "CUBOS",
    carouselNameEs: "CASA CUBOS",
    carouselNameEn: "CUBES HOUSE",
    nameEn: "CUBES",
    mobilePortada: casaCubosPortadaMobile,
    galleryImages: [casaCubos1, casaCubos2, casaCubos3, casaCubos4],
    carouselImages: [
      casaCubosCarousel1,
      casaCubosCarousel2,
      casaCubosCarousel3,
      casaCubosCarousel4,
    ],
  },
  {
    id: 15,
    name: "4 BALCONES",
    carouselNameEs: "REFORMA 4 BALCONES",
    carouselNameEn: "4 BALCONIES REFORM",
    nameEn: "BALCONIES",
    customNameEs: "REFORMA",
    customNameEn: "REFORM",
    mobilePortada: casaBalconesPortadaMobile,
    galleryImages: [casaBalcones1, casaBalcones2, casaBalcones3, casaBalcones4],
    carouselImages: [
      casaBalconesCarousel1,
      casaBalconesCarousel2,
      casaBalconesCarousel3,
    ],
  },
  {
    id: 16,
    name: "LASTRA",
    carouselNameEs: "REFORMA LASTRA",
    carouselNameEn: "LASTRA REFORM",
    customNameEs: "REFORMA",
    customNameEn: "REFORM",
    mobilePortada: casaLastraPortadaMobile,
    galleryImages: [casaLastra1, casaLastra2, casaLastra3, casaLastra4],
    carouselImages: [
      casaLastraCarousel1,
      casaLastraCarousel2,
      casaLastraCarousel3,
      casaLastraCarousel4,
    ],
  },
  {
    id: 17,
    name: "CASTELLI",
    carouselNameEs: "REFORMA CASTELLI",
    carouselNameEn: "CASTELLI REFORM",
    customNameEs: "REFORMA",
    customNameEn: "REFORM",
    mobilePortada: casaCastelliPortadaMobile,
    galleryImages: [casaCastelli1, casaCastelli2, casaCastelli3, casaCastelli4],
    carouselImages: [
      casaCastelliCarousel1,
      casaCastelliCarousel2,
      casaCastelliCarousel3,
      casaCastelliCarousel4,
    ],
  },
  {
    id: 18,
    name: "MAGALLANES",
    carouselNameEs: "CASA MAGALLANES",
    carouselNameEn: "MAGALLANES HOUSE",
    mobilePortada: casaMagallanesPortadaMobile,
    galleryImages: [casaMagallanes1, casaMagallanes2, casaMagallanes3],
    carouselImages: [
      casaMagallanesCarousel1,
      casaMagallanesCarousel2,
      casaMagallanesCarousel3,
    ],
  },
  {
    id: 19,
    name: "ARIHOUSE",
    carouselNameEs: "EDIFICIO ARIHOUSE",
    carouselNameEn: "ARIHOUSE BUILDING",
    mobilePortada: casaAirhousePortadaMobile,
    galleryImages: [casaAirhouse1, casaAirhouse2, casaAirhouse3],
    carouselImages: [
      casaAirhouseCarousel1,
      casaAirhouseCarousel2,
      casaAirhouseCarousel3,
    ],
  },
  {
    id: 20,
    name: "FUEGOS",
    carouselNameEs: "CASA FUEGOS",
    carouselNameEn: "FIRES HOUSE",
    nameEn: "FIRES",
    mobilePortada: casaFuegosPortadaMobile,
    galleryImages: [casaFuegos1, casaFuegos2, casaFuegos3, casaFuegos4],
    carouselImages: [
      casaFuegosCarousel1,
      casaFuegosCarousel2,
      casaFuegosCarousel3,
      casaFuegosCarousel4,
    ],
  },
];

export default residentialProjects;
