import SlickCarousel from "@components/SlickCarousel/SlickCarousel";
import styles from "./DetailProject.module.scss";
import { useTranslation } from "react-i18next";

const DetailProject = ({ project }) => {
  const { i18n } = useTranslation("global");

  return (
    <div className={styles.detailProjectContainer}>
      <div className={styles.titleWrapper}>
        <div className={styles.titleContainer}>
          <div className={styles.projectTitleAndNumber}>
            {i18n.language === "es" ||
            project.carouselNameEn === null ||
            project.carouselNameEn === undefined
              ? project.carouselNameEs
              : project.carouselNameEn}
          </div>
          <div className={styles.projectNumber}>
            {(project.id + 1).toString().padStart(2, "0")}
          </div>
        </div>
      </div>
      <div className={styles.carouselContainer}>
        <SlickCarousel
          carouselImages={project.carouselImages}
          galleryImages={project.galleryImages}
        />
      </div>
    </div>
  );
};

export default DetailProject;
