import React from "react";
import Header from "@components/Header/Header";
import Footer from "@components/Footer/Footer";
import ExperimentationsSection from "@components/ExperimentationsSection/ExperimentationsSection";

function ExperimentationPage() {
  return (
    <div>
      <Header />
      <ExperimentationsSection />
      <Footer />
    </div>
  );
}

export default ExperimentationPage;
