import React from "react";
import Header from "@components/Header/Header";
import Footer from "@components/Footer/Footer";
import ProjectsSection from "@components/ProjectSection/ProjectsSection";
import HeroSection from "@components/HeroSection/HeroSection";
import AboutSection from "@components/AboutSection/AboutSection";
import { Routes as RoutesNames } from "@constants/routes";

function Landing() {
  return (
    <div>
      <main>
        <Header currentPage={RoutesNames.Landing} />
        <HeroSection />
        <ProjectsSection />
        <AboutSection />
      </main>
      <Footer currentPage={RoutesNames.Landing} />
    </div>
  );
}

export default Landing;
