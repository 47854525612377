import styles from "./TeamSection.module.scss";
import grizzoHistory from "@assets/images/teamSection/grizzoHistory.jpg";
import teamMember1 from "@assets/images/teamSection/teamMember1.jpg";
import teamMember2 from "@assets/images/teamSection/teamMember2.jpg";
import teamMember3 from "@assets/images/teamSection/teamMember3.jpg";
import teamMember4 from "@assets/images/teamSection/teamMember4.jpg";
import { useTranslation } from "react-i18next";

function TeamSection() {
  const [t] = useTranslation("global");
  return (
    <div id="teamSection" className={styles.teamSectionContainer}>
      <div className={styles.teamSectionTitle}>
        <p>About Us</p>
      </div>
      <div className={styles.grizzoHistory}>
        <div className={styles.grizzoHistoryText}>
          <p>{t("aboutUs.firstParagraph")}</p>
          <p>{t("aboutUs.secondParagraph")}</p>
          <p>{t("aboutUs.thirdParagraph")}</p>
        </div>
        <img src={grizzoHistory} alt="Atmosfera" />
      </div>
      <div className={styles.teamCardsContainer}>
        <div className={styles.teamCardFirstRow}>
          <div className={`${styles.teamCard} ${styles.teamCardMarginBottom}`}>
            <img src={teamMember2} alt="Member2" />
            <p>{t("aboutUs.lucila")}</p>
          </div>
          <div className={styles.teamCard}>
            <img src={teamMember1} alt="Member1" />
            <p>{t("aboutUs.federico")}</p>
          </div>
        </div>
        <div className={styles.teamCardSecondRow}>
          <div className={`${styles.teamCard} ${styles.teamCardMarginBottom}`}>
            <img src={teamMember4} alt="Member4" />
            <p>{t("aboutUs.rocio")}</p>
          </div>
          <div className={styles.teamCard}>
            <img src={teamMember3} alt="Member3" />
            <p>{t("aboutUs.juana")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamSection;
