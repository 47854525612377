import React from "react";
import Header from "@components/Header/Header";
import Footer from "@components/Footer/Footer";
import DetailProject from "@components/DetailProject/DetailProject";
import { useLocation } from "react-router-dom";

function DetailPage() {
  const location = useLocation();
  const { project } = location.state || {};

  return (
    <div>
      <Header />
      <DetailProject project={project} />
      <Footer />
    </div>
  );
}

export default DetailPage;
