import React from "react";
import Header from "@components/Header/Header";
import Footer from "@components/Footer/Footer";
import TeamSection from "@components/TeamSection/TeamSection";

function TeamPage() {
  return (
    <div>
      <Header />
      <TeamSection />
      <Footer />
    </div>
  );
}

export default TeamPage;
