import zapiolaPortadaMobile from "@assets/images/projects/mobile/publications/zapiola/zapiolaPortadaMobile.jpg";
import zapiolaWildprint1 from "@assets/images/projects/publications/zapiolaWildprint/zapiolaWildprint1.jpg";
import zapiolaWildprint2 from "@assets/images/projects/publications/zapiolaWildprint/zapiolaWildprint2.jpg";
import zapiolaWildprint3 from "@assets/images/projects/publications/zapiolaWildprint/zapiolaWildprint3.jpg";
import zapiolaWildprint4 from "@assets/images/projects/publications/zapiolaWildprint/zapiolaWildprint4.jpg";
import localesPortadaMobile from "@assets/images/projects/mobile/publications/locales/localesPortadaMobile.jpg";
import localesClarin1 from "@assets/images/projects/publications/localesClarin/localesClarin1.jpg";
import localesClarin2 from "@assets/images/projects/publications/localesClarin/localesClarin2.jpg";
import localesClarin3 from "@assets/images/projects/publications/localesClarin/localesClarin3.jpg";
import vlPortadaMobile from "@assets/images/projects/mobile/publications/vl/vlPortadaMobile.jpg";
import vlLiving1 from "@assets/images/projects/publications/vlLiving/vlLiving1.jpg";
import vlLiving2 from "@assets/images/projects/publications/vlLiving/vlLiving2.jpg";
import vlLiving3 from "@assets/images/projects/publications/vlLiving/vlLiving3.jpg";
import cervantesPortadaMobile from "@assets/images/projects/mobile/publications/cervantes/cervantesPortadaMobile.jpg";
import cervantesClarin1 from "@assets/images/projects/publications/cervantesClarin/cervantesClarin1.jpg";
import cervantesClarin2 from "@assets/images/projects/publications/cervantesClarin/cervantesClarin2.jpg";

const publications = [
  {
    id: 0,
    name: "ZAPIOLA",
    nameEs: "EN WIDEPRINT",
    nameEn: "IN WIDEPRINT",
    mobilePortada: zapiolaPortadaMobile,
    images: [
      zapiolaWildprint1,
      zapiolaWildprint2,
      zapiolaWildprint3,
      zapiolaWildprint4,
    ],
  },
  {
    id: 1,
    name: "LOCALES",
    nameEs: "EN CLARIN",
    nameEn: "IN CLARIN",
    mobilePortada: localesPortadaMobile,
    images: [localesClarin1, localesClarin2, localesClarin3],
  },
  {
    id: 2,
    name: "VL",
    nameEs: "EN REVISTA LIVING",
    nameEn: "IN LIVING MAGAZINE",
    mobilePortada: vlPortadaMobile,
    images: [vlLiving1, vlLiving2, vlLiving3],
  },
  {
    id: 3,
    name: "CERVANTES",
    nameEs: "EN CLARIN",
    nameEn: "IN CLARIN",
    mobilePortada: cervantesPortadaMobile,
    images: [cervantesClarin1, cervantesClarin2],
  },
];

export default publications;
