import elEstudio1 from "@assets/images/projects/commercial/elEstudio/gallery/elEstudio1.jpg";
import elEstudio2 from "@assets/images/projects/commercial/elEstudio/gallery/elEstudio2.jpg";
import elEstudio3 from "@assets/images/projects/commercial/elEstudio/gallery/elEstudio3.jpg";
import elEstudio4 from "@assets/images/projects/commercial/elEstudio/gallery/elEstudio4.jpg";
import zapiola1 from "@assets/images/projects/commercial/zapiola/gallery/zapiola1.jpg";
import zapiola2 from "@assets/images/projects/commercial/zapiola/gallery/zapiola2.jpg";
import zapiola3 from "@assets/images/projects/commercial/zapiola/gallery/zapiola3.jpg";
import zapiola4 from "@assets/images/projects/commercial/zapiola/gallery/zapiola4.jpg";
import laExposicion1 from "@assets/images/projects/commercial/laExposicion/gallery/laExposicion1.jpg";
import laExposicion2 from "@assets/images/projects/commercial/laExposicion/gallery/laExposicion2.jpg";
import laExposicion3 from "@assets/images/projects/commercial/laExposicion/gallery/laExposicion3.jpg";
import marcia1 from "@assets/images/projects/commercial/marcia/gallery/marcia1.jpg";
import marcia2 from "@assets/images/projects/commercial/marcia/gallery/marcia2.jpg";
import marcia3 from "@assets/images/projects/commercial/marcia/gallery/marcia3.jpg";
import marcia4 from "@assets/images/projects/commercial/marcia/gallery/marcia4.jpg";
import cacto1 from "@assets/images/projects/commercial/cacto/gallery/cacto1.jpg";
import cacto2 from "@assets/images/projects/commercial/cacto/gallery/cacto2.jpg";
import cacto3 from "@assets/images/projects/commercial/cacto/gallery/cacto3.jpg";
import cacto4 from "@assets/images/projects/commercial/cacto/gallery/cacto4.jpg";
import norte1 from "@assets/images/projects/commercial/norte/gallery/norte1.jpg";
import norte2 from "@assets/images/projects/commercial/norte/gallery/norte2.jpg";
import norte3 from "@assets/images/projects/commercial/norte/gallery/norte3.jpg";
import norte4 from "@assets/images/projects/commercial/norte/gallery/norte4.jpg";
import sorvo1 from "@assets/images/projects/commercial/sorvo/gallery/sorvo1.jpg";
import sorvo2 from "@assets/images/projects/commercial/sorvo/gallery/sorvo2.jpg";
import sorvo3 from "@assets/images/projects/commercial/sorvo/gallery/sorvo3.jpg";
import sorvo4 from "@assets/images/projects/commercial/sorvo/gallery/sorvo4.jpg";
import montagu1 from "@assets/images/projects/commercial/montagu/gallery/montagu1.jpg";
import montagu2 from "@assets/images/projects/commercial/montagu/gallery/montagu2.jpg";
import montagu3 from "@assets/images/projects/commercial/montagu/gallery/montagu3.jpg";
import montagu4 from "@assets/images/projects/commercial/montagu/gallery/montagu4.jpg";
import arena1 from "@assets/images/projects/commercial/arena/gallery/arena1.jpg";
import arena2 from "@assets/images/projects/commercial/arena/gallery/arena2.jpg";
import arena3 from "@assets/images/projects/commercial/arena/gallery/arena3.jpg";
import arena4 from "@assets/images/projects/commercial/arena/gallery/arena4.jpg";
import arena5 from "@assets/images/projects/commercial/arena/gallery/arena5.jpg";
import arena6 from "@assets/images/projects/commercial/arena/gallery/arena6.jpg";
import arena7 from "@assets/images/projects/commercial/arena/gallery/arena7.jpg";
import arena8 from "@assets/images/projects/commercial/arena/gallery/arena8.jpg";
import simbron1 from "@assets/images/projects/commercial/simbron/gallery/simbron1.jpg";
import simbron2 from "@assets/images/projects/commercial/simbron/gallery/simbron2.jpg";
import simbron3 from "@assets/images/projects/commercial/simbron/gallery/simbron3.jpg";
import simbron4 from "@assets/images/projects/commercial/simbron/gallery/simbron4.jpg";

//Carousel Images
import elEstudioCarousel1 from "@assets/images/projects/commercial/elEstudio/carousel/1.jpg";
import elEstudioCarousel2 from "@assets/images/projects/commercial/elEstudio/carousel/2.jpg";
import elEstudioCarousel3 from "@assets/images/projects/commercial/elEstudio/carousel/3.jpg";
import elEstudioCarousel4 from "@assets/images/projects/commercial/elEstudio/carousel/4.jpg";
import zapiolaCarousel1 from "@assets/images/projects/commercial/zapiola/carousel/1.jpg";
import zapiolaCarousel2 from "@assets/images/projects/commercial/zapiola/carousel/2.jpg";
import zapiolaCarousel3 from "@assets/images/projects/commercial/zapiola/carousel/3.jpg";
import zapiolaCarousel4 from "@assets/images/projects/commercial/zapiola/carousel/4.jpg";
import laExposicionCarousel1 from "@assets/images/projects/commercial/laExposicion/carousel/1.jpg";
import laExposicionCarousel2 from "@assets/images/projects/commercial/laExposicion/carousel/2.jpg";
import laExposicionCarousel3 from "@assets/images/projects/commercial/laExposicion/carousel/3.jpg";
import marciaCarousel1 from "@assets/images/projects/commercial/marcia/carousel/1.jpg";
import marciaCarousel2 from "@assets/images/projects/commercial/marcia/carousel/2.jpg";
import marciaCarousel3 from "@assets/images/projects/commercial/marcia/carousel/3.jpg";
import marciaCarousel4 from "@assets/images/projects/commercial/marcia/carousel/4.jpg";
import cactoCarousel1 from "@assets/images/projects/commercial/cacto/carousel/1.jpg";
import cactoCarousel2 from "@assets/images/projects/commercial/cacto/carousel/2.jpg";
import cactoCarousel3 from "@assets/images/projects/commercial/cacto/carousel/3.jpg";
import cactoCarousel4 from "@assets/images/projects/commercial/cacto/carousel/4.jpg";
import norteCarousel1 from "@assets/images/projects/commercial/norte/carousel/1.jpg";
import norteCarousel2 from "@assets/images/projects/commercial/norte/carousel/2.jpg";
import norteCarousel3 from "@assets/images/projects/commercial/norte/carousel/3.jpg";
import norteCarousel4 from "@assets/images/projects/commercial/norte/carousel/4.jpg";
import sorvoCarousel1 from "@assets/images/projects/commercial/sorvo/carousel/1.jpg";
import sorvoCarousel2 from "@assets/images/projects/commercial/sorvo/carousel/2.jpg";
import sorvoCarousel3 from "@assets/images/projects/commercial/sorvo/carousel/3.jpg";
import sorvoCarousel4 from "@assets/images/projects/commercial/sorvo/carousel/4.jpg";
import montaguCarousel1 from "@assets/images/projects/commercial/montagu/carousel/1.jpg";
import montaguCarousel2 from "@assets/images/projects/commercial/montagu/carousel/2.jpg";
import montaguCarousel3 from "@assets/images/projects/commercial/montagu/carousel/3.jpg";
import montaguCarousel4 from "@assets/images/projects/commercial/montagu/carousel/4.jpg";
import arenaCarousel1 from "@assets/images/projects/commercial/arena/carousel/1.jpg";
import arenaCarousel2 from "@assets/images/projects/commercial/arena/carousel/2.jpg";
import arenaCarousel3 from "@assets/images/projects/commercial/arena/carousel/3.jpg";
import arenaCarousel4 from "@assets/images/projects/commercial/arena/carousel/4.jpg";
import arenaCarousel5 from "@assets/images/projects/commercial/arena/carousel/5.jpg";
import arenaCarousel6 from "@assets/images/projects/commercial/arena/carousel/6.jpg";
import arenaCarousel7 from "@assets/images/projects/commercial/arena/carousel/7.jpg";
import arenaCarousel8 from "@assets/images/projects/commercial/arena/carousel/8.jpg";
import simbronCarousel1 from "@assets/images/projects/commercial/simbron/carousel/1.jpg";
import simbronCarousel2 from "@assets/images/projects/commercial/simbron/carousel/2.jpg";
import simbronCarousel3 from "@assets/images/projects/commercial/simbron/carousel/3.jpg";
import simbronCarousel4 from "@assets/images/projects/commercial/simbron/carousel/4.jpg";

//Mobile Portadas
import elEstudioMobilePortada from "@assets/images/projects/mobile/commercial/elEstudioMobilePortada.jpg";
import zapiolaMobilePortada from "@assets/images/projects/mobile/commercial/zapiolaMobilePortada.jpg";
import laExposicionMobilePortada from "@assets/images/projects/mobile/commercial/laExposicionMobilePortada.jpg";
import marciaMobilePortada from "@assets/images/projects/mobile/commercial/marciaMobilePortada.jpg";
import cactoMobilePortada from "@assets/images/projects/mobile/commercial/cactoMobilePortada.jpg";
import montaguMobilePortada from "@assets/images/projects/mobile/commercial/montaguMobilePortada.jpg";
import arenaMobilePortada from "@assets/images/projects/mobile/commercial/arenaMobilePortada.jpg";
import simbronMobilePortada from "@assets/images/projects/mobile/commercial/simbronMobilePortada.jpg";
import norteMobilePortada from "@assets/images/projects/mobile/commercial/norteMobilePortada.jpg";
import sorvoMobilePortada from "@assets/images/projects/mobile/commercial/sorvoMobilePortada.jpg";

const commercialProjects = [
  {
    id: 0,
    name: "EL ESTUDIO",
    nameEn: "OFFICE",
    carouselNameEs: "EL ESTUDIO",
    carouselNameEn: "OFFICE",
    mobilePortada: elEstudioMobilePortada,
    galleryImages: [elEstudio1, elEstudio2, elEstudio3, elEstudio4],
    carouselImages: [
      elEstudioCarousel1,
      elEstudioCarousel2,
      elEstudioCarousel3,
      elEstudioCarousel4,
    ],
  },
  {
    id: 1,
    name: "ZAPIOLA",
    carouselNameEs: "ZAPIOLA",
    nameEn: null,
    mobilePortada: zapiolaMobilePortada,
    galleryImages: [zapiola1, zapiola2, zapiola3, zapiola4],
    carouselImages: [
      zapiolaCarousel1,
      zapiolaCarousel2,
      zapiolaCarousel3,
      zapiolaCarousel4,
    ],
  },
  {
    id: 2,
    name: "LA EXPOSICION",
    carouselNameEs: "LA EXPOSICION",
    nameEn: null,
    galleryImages: [laExposicion1, laExposicion2, laExposicion3],
    mobilePortada: laExposicionMobilePortada,
    carouselImages: [
      laExposicionCarousel1,
      laExposicionCarousel2,
      laExposicionCarousel3,
    ],
  },
  {
    id: 3,
    name: "MARCIA",
    carouselNameEs: "MARCIA",
    nameEn: null,
    mobilePortada: marciaMobilePortada,
    galleryImages: [marcia1, marcia2, marcia3, marcia4],
    carouselImages: [
      marciaCarousel1,
      marciaCarousel2,
      marciaCarousel3,
      marciaCarousel4,
    ],
  },
  {
    id: 4,
    name: "CACTO",
    carouselNameEs: "CACTO",
    nameEn: null,
    mobilePortada: cactoMobilePortada,
    galleryImages: [cacto1, cacto2, cacto3, cacto4],
    carouselImages: [
      cactoCarousel1,
      cactoCarousel2,
      cactoCarousel3,
      cactoCarousel4,
    ],
  },
  {
    id: 5,
    name: "NORTE",
    carouselNameEs: "NORTE",
    nameEn: null,
    mobilePortada: norteMobilePortada,
    galleryImages: [norte1, norte2, norte3, norte4],
    carouselImages: [
      norteCarousel1,
      norteCarousel2,
      norteCarousel3,
      norteCarousel4,
    ],
  },
  {
    id: 6,
    name: "SORVO",
    carouselNameEs: "SORVO",
    nameEn: null,
    mobilePortada: sorvoMobilePortada,
    galleryImages: [sorvo1, sorvo2, sorvo3, sorvo4],
    carouselImages: [
      sorvoCarousel1,
      sorvoCarousel2,
      sorvoCarousel3,
      sorvoCarousel4,
    ],
  },
  {
    id: 7,
    name: "MONTAGU",
    carouselNameEs: "MONTAGU",
    nameEn: null,
    mobilePortada: montaguMobilePortada,
    galleryImages: [montagu1, montagu2, montagu3, montagu4],
    carouselImages: [
      montaguCarousel1,
      montaguCarousel2,
      montaguCarousel3,
      montaguCarousel4,
    ],
  },
  {
    id: 8,
    name: "ARENA",
    carouselNameEs: "ARENA",
    nameEn: null,
    mobilePortada: arenaMobilePortada,
    galleryImages: [
      arena1,
      arena2,
      arena3,
      arena4,
      arena5,
      arena6,
      arena7,
      arena8,
    ],
    carouselImages: [
      arenaCarousel1,
      arenaCarousel2,
      arenaCarousel3,
      arenaCarousel4,
      arenaCarousel5,
      arenaCarousel6,
      arenaCarousel7,
      arenaCarousel8,
    ],
  },
  {
    id: 9,
    name: "SIMBRON",
    carouselNameEs: "SIMBRON",
    nameEn: null,
    galleryImages: [simbron1, simbron2, simbron3, simbron4],
    mobilePortada: simbronMobilePortada,
    carouselImages: [
      simbronCarousel1,
      simbronCarousel2,
      simbronCarousel3,
      simbronCarousel4,
    ],
  },
];

export default commercialProjects;
