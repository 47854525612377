import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./ProjectsSection.module.scss";
import commercialBackground from "@assets/images/projects/comercial-background.jpg";
import residentialBackground from "@assets/images/projects/residencial-background.jpg";
import { Routes as RoutesNames } from "@constants/routes";
import { scrollToTop } from "@utils/scrollToTop";
import { useTranslation } from "react-i18next";

function ProjectsSection() {
  const [t] = useTranslation("global");

  return (
    <div id="projectsSection" className={styles.projectsSection}>
      <h1 className={styles.projectsSectionTitle}>
        {t("landing.projectSectionTitle")}
      </h1>
      <div className={styles.projectsImagesContainer}>
        <div className={styles.imageContainer} onClick={scrollToTop}>
          <Link to={RoutesNames.Residential}>
            <img
              loading="lazy"
              src={residentialBackground}
              alt="Residential Projects"
              className={styles.projectImage}
            />
            <span className={styles.imageTitle}>
              {t("landing.residentialProjects")}
            </span>
          </Link>
        </div>
        <div className={styles.imageContainer}>
          <Link to={RoutesNames.Comercial} onClick={scrollToTop}>
            <img
              loading="lazy"
              src={commercialBackground}
              alt="Comercial Projects"
              className={cn(styles.projectImage, styles.rightProjectImage)}
            />
            <span className={styles.imageTitle}>
              {t("landing.commercialProjects")}
            </span>
          </Link>
        </div>
      </div>
      <div className={styles.description}>
        <p>{t("copyright")}</p>
        <p>2020-2024©</p>
      </div>
    </div>
  );
}

export default ProjectsSection;
